import {
  Active_List,
  EDIT_USER,
  SETImage1,
  SETImage2,
  SETImage3,
  SETImage4,
} from "../Constant/adminConstant";

const initialState = {
  loading: false,
  adminDetails: null,
  ActiveList: null,
  error: null,
  editUserData: null,
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADMIN_LOGIN_SUCCESS":
      return {
        ...state,
        adminDetails: action.payload,
        error: null,
      };

    case Active_List:
      return {
        ...state,
        ActiveList: action.payload,
        error: null,
      };
    case EDIT_USER:
      return {
        ...state,
        editUserData: action.payload,
        error: null,
      };

    case "LOGOUT":
      return {};
    default:
      return state;
  }
};

export default adminReducer;
