import React, { useEffect, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';

const PowerBIFrame = ({ src, height }) => {
  const iframeRef = useRef(null);

  // useEffect(() => {
  //   const disableRightClick = (e) => {
  //     e.preventDefault();
  //   };

  //   const iframe = iframeRef.current;

  //   if (iframe) {
  //     iframe.addEventListener('contextmenu', disableRightClick);
  //   }

  //   return () => {
  //     if (iframe) {
  //       iframe.removeEventListener('contextmenu', disableRightClick);
  //     }
  //   };
  // }, []);

  // useEffect(() => {
  //   const handleLoad = () => {
  //     const iframeDoc = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
  //     iframeDoc.addEventListener('contextmenu', (e) => {
  //       e.preventDefault();
  //     });
  //   };

  //   if (iframeRef.current) {
  //     iframeRef.current.addEventListener('load', handleLoad);
  //   }

  //   return () => {
  //     if (iframeRef.current) {
  //       iframeRef.current.removeEventListener('load', handleLoad);
  //     }
  //   };
  // }, []);
  const [showIframe, setShowIframe] = useState(false);

  useEffect(() => {
    if (src) {
      // Show loader for 3 seconds, then display the iframe
      const timer = setTimeout(() => {
        setShowIframe(true);
      }, 7000);

      return () => clearTimeout(timer); // Clear the timeout if the component unmounts
    }
  }, [src]);

  return (
    <div className='PowerBIFrame' style={{height:height}}>
   {!showIframe && (
        <div className="customLoader">
          <Spinner animation="grow" size="sm" />
          <h6 className="mb-0 mt-2">Loading</h6>
        </div>
      )}
      {src && (
        <iframe
          src={src}
          width="100%"
          height={height}
          style={{ border: 'none', display: showIframe ? 'block' : 'none' }}
          title="Power BI Graph"
          allowFullScreen
        />
      )}

    </div>
  );
};

export default PowerBIFrame;
