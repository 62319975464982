import { connect } from 'react-redux'
import { makeStyles } from "@material-ui/styles";
import { Checkbox, FormGroup, FormControlLabel, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Typography, Button, TablePagination, Box, Paper, CardContent, Modal, IconButton, TextField, FormControl, Select, InputLabel, MenuItem, Alert } from "@mui/material";
import * as Action from "../../actions/adminAction"
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from '@mui/icons-material/Send';
import { useNavigate } from "react-router-dom";
import PersonAddRounded from "@mui/icons-material/PersonAddRounded";
import { Edit, Visibility, Delete } from "@mui/icons-material";
import React, { useEffect, useState } from 'react';


export const AddTrainer = ({ }) => {
    const classes = useStyles()
    return (
        <div className={classes.main}>
            <Box sx={{ flex: 1 }}>
                <h1>Add Trainer</h1>
            </Box>
            <div className={classes.middlePosition}>
                <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                        style={{ width: 300 }}
                        // error={TrainerName.match(/[0-9+@#$&%!~]/)}
                        id="outlined-adornment-TrainerName"
                        label="First Name"
                        value=""
                    // onChange={(e) => setTrainerName(e.target.value)}
                    // helperText={NameError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Enter Trainer Name</span> : null}
                    />
                </FormControl>
                <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                        style={{ width: 300 }}
                        // error={TrainerName.match(/[0-9+@#$&%!~]/)}
                        id="outlined-adornment-TrainerName"
                        label="Middle Name"
                        value=""
                    // onChange={(e) => setTrainerName(e.target.value)}
                    // helperText={NameError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Enter Trainer Name</span> : null}
                    />
                </FormControl>
                <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                        style={{ width: 300 }}
                        // error={TrainerName.match(/[0-9+@#$&%!~]/)}
                        id="outlined-adornment-TrainerName"
                        label="Last Name"
                        value=""
                    // onChange={(e) => setTrainerName(e.target.value)}
                    // helperText={NameError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Enter Trainer Name</span> : null}
                    />
                </FormControl>
            </div>
            <div className={classes.middlePosition}>
                <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                        style={{ width: 300 }}
                        // error={TrainerName.match(/[0-9+@#$&%!~]/)}
                        id="outlined-adornment-TrainerName"
                        label="Contact Number"
                        value=""
                    // onChange={(e) => setTrainerName(e.target.value)}
                    // helperText={NameError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Enter Trainer Name</span> : null}
                    />
                    <FormGroup>
                        <FormControlLabel control={<Checkbox defaultChecked />} label="Same WhatsApp number" />
                        {/* <FormControlLabel disabled control={<Checkbox />} label="Disabled" /> */}
                    </FormGroup>
                </FormControl>

                <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                        style={{ width: 300 }}
                        // error={TrainerName.match(/[0-9+@#$&%!~]/)}
                        id="outlined-adornment-TrainerName"
                        label="WhatsApp Number"
                        value=""
                    // onChange={(e) => setTrainerName(e.target.value)}
                    // helperText={NameError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Enter Trainer Name</span> : null}
                    />
                </FormControl>
                <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                        style={{ width: 300 }}
                        // error={TrainerName.match(/[0-9+@#$&%!~]/)}
                        id="outlined-adornment-TrainerName"
                        label="Email Id"
                        value=""
                    // onChange={(e) => setTrainerName(e.target.value)}
                    // helperText={NameError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Enter Trainer Name</span> : null}
                    />
                </FormControl>
            </div>
            <div className={classes.middlePosition}>
                <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                        style={{ width: 300 }}
                        // error={TrainerName.match(/[0-9+@#$&%!~]/)}
                        id="outlined-adornment-TrainerName"
                        label="Contact Number"
                        value=""
                    // onChange={(e) => setTrainerName(e.target.value)}
                    // helperText={NameError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Enter Trainer Name</span> : null}
                    />
                </FormControl>
                <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                        style={{ width: 300 }}
                        // error={TrainerName.match(/[0-9+@#$&%!~]/)}
                        id="outlined-adornment-TrainerName"
                        label="WhatsApp Number"
                        value=""
                    // onChange={(e) => setTrainerName(e.target.value)}
                    // helperText={NameError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Enter Trainer Name</span> : null}
                    />
                </FormControl>
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    main: {
        display: "flex",
        // alignItems: "center",
        justifyContent: "center",
        flexDirection: 'column',
        marginTop: 30
    },
    card: {
        width: '1200px',
        borderRadius: '20px !important',
        margin: '20px',
        padding: '20px',
    },
    passmodal: {
        width: '350px',
        background: 'white',
        borderRadius: '20px !important',
        margin: '20px',
        padding: '20px',
    },
    profilemodal: {
        width: '800px',
        background: 'white',
        borderRadius: '20px !important',
        margin: '20px',
        padding: '20px',
    },
    middlePosition: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'start',
        justifyContent: 'start'
    },
    input: {
        width: '70%',
        background: 'white',
        marginTop: '20px',
        padding: '5px 15px'
    },
}));


const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(AddTrainer)