import React, { useState, useEffect } from "react";
import { connect } from 'react-redux'
import { makeStyles } from "@material-ui/styles";
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, TablePagination, Box, Paper, InputLabel, CircularProgress, Select, FormControl, Card, CardContent, Typography, CardActions, Button, } from "@mui/material";
import * as Action from "../../actions/adminAction"
import { useNavigate } from "react-router-dom";

export const Dashboard = ({ getDashboardCounts, setActiveList }) => {

    const classes = useStyles()
    const navigate = useNavigate()

    const [loader, setLoader] = useState(false)

    //data variables
    const [counts, setCounts] = useState([])

    useEffect(() => {
        getDashboardCount();
    }, [])

    const getDashboardCount = async () => {
        const result = await getDashboardCounts()
        if (result) {
            setCounts(result)
            setLoader(false)
            console.log("Counts", result)
        } else {
            setLoader(false)
        }
    }

    return (
        <div className={classes.main}>
            {
                loader
                    ? <CircularProgress color="primary" />
                    : <div>
                        <Box>
                            <div style={{ display: 'flex', flexDirection: 'row',flexWrap:'wrap',alignItems:'start',justifyContent:'center' }}>
                                <Card sx={{ minWidth: 275 }} style={{ backgroundColor: 'whitesmoke', textAlign: 'center', marginTop: 20, marginBottom: 20, marginLeft: 10 }} >
                                    <CardContent style={{ padding: 0 }}>
                                        <Typography style={{ backgroundColor: '#1d5a96', color: 'white', fontSize: 20, fontWeight: 600, padding: 5 }}>
                                            All Assigned Trainers
                                        </Typography>
                                        <Typography style={{ margin: 20, fontSize: 24, fontWeight: 500 }}>
                                            {counts?.allAssignedTrainerCount ? counts?.allAssignedTrainerCount : 0}
                                        </Typography>
                                        <center>
                                            <Button variant="contained" style={{ marginBottom: 10 }} onClick={() => { navigate("/admin/assigned-trainer-list"); setActiveList("/admin/assigned-trainer-list") }} >View</Button>
                                        </center>
                                    </CardContent>
                                </Card>
                                <Card sx={{ minWidth: 275 }} style={{ backgroundColor: 'whitesmoke', textAlign: 'center', marginTop: 20, marginBottom: 20, marginLeft: 10 }} >
                                    <CardContent style={{ padding: 0 }}>
                                        <Typography style={{ backgroundColor: '#1d5a96', color: 'white', fontSize: 20, fontWeight: 600, padding: 5 }}>
                                            All Approved Training
                                        </Typography>
                                        <Typography style={{ margin: 20, fontSize: 24, fontWeight: 500 }}>
                                            {counts?.approvalTrainingCount ? counts?.approvalTrainingCount : 0}
                                        </Typography>
                                        <center>
                                            <Button variant="contained" style={{ marginBottom: 10 }} onClick={() => { navigate("/admin/Approved-document"); setActiveList("/admin/Approved-document") }} >View</Button>
                                        </center>
                                    </CardContent>
                                </Card>
                                <Card sx={{ minWidth: 275 }} style={{ backgroundColor: 'whitesmoke', textAlign: 'center', marginTop: 20, marginBottom: 20, marginLeft: 10 }} >
                                    <CardContent style={{ padding: 0 }}>
                                        <Typography style={{ backgroundColor: '#1d5a96', color: 'white', fontSize: 20, fontWeight: 600, padding: 5 }}>
                                            Total Districts
                                        </Typography>
                                        <Typography style={{ margin: 20, fontSize: 24, fontWeight: 500 }}>
                                            {counts?.distric ? counts?.distric : 0}
                                        </Typography>
                                        <center>
                                            <Button variant="contained" style={{ marginBottom: 10 }} onClick={() => { navigate("/admin/all-district-list"); setActiveList("/admin/all-district-list") }} >View</Button>
                                        </center>
                                    </CardContent>
                                </Card>
                                <Card sx={{ minWidth: 275 }} style={{ backgroundColor: 'whitesmoke', textAlign: 'center', marginTop: 20, marginBottom: 20, marginLeft: 10 }} >
                                    <CardContent style={{ padding: 0 }}>
                                        <Typography style={{ backgroundColor: '#1d5a96', color: 'white', fontSize: 20, fontWeight: 600, padding: 5 }}>
                                            Total Taluka
                                        </Typography>
                                        <Typography style={{ margin: 20, fontSize: 24, fontWeight: 500 }}>
                                            {counts?.taluka ? counts?.taluka : 0}
                                        </Typography>
                                        <center>
                                            <Button variant="contained" style={{ marginBottom: 10 }} onClick={() => { navigate("/admin/all-taluka-list"); setActiveList("/admin/all-taluka-list") }} >View</Button>
                                        </center>
                                    </CardContent>
                                </Card>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row',flexWrap:'wrap',alignItems:'start',justifyContent:'center' }}>
                                <Card sx={{ minWidth: 275 }} style={{ backgroundColor: 'whitesmoke', textAlign: 'center', marginTop: 20, marginBottom: 20, marginLeft: 10 }} >
                                    <CardContent style={{ padding: 0 }}>
                                        <Typography style={{ backgroundColor: '#1d5a96', color: 'white', fontSize: 20, fontWeight: 600, padding: 5 }}>
                                            Total Trainers
                                        </Typography>
                                        <Typography style={{ margin: 20, fontSize: 24, fontWeight: 500 }}>
                                            {counts?.trainer ? counts?.trainer : 0}
                                        </Typography>
                                        <center>
                                            <Button variant="contained" style={{ marginBottom: 10 }} onClick={() => { navigate("/admin/assign-trainer"); setActiveList("/admin/assign-trainer") }} >View</Button>
                                        </center>
                                    </CardContent>
                                </Card>
                                <Card sx={{ minWidth: 275 }} style={{ backgroundColor: 'whitesmoke', textAlign: 'center', marginTop: 20, marginBottom: 20, marginLeft: 10 }} >
                                    <CardContent style={{ padding: 0 }}>
                                        <Typography style={{ backgroundColor: '#1d5a96', color: 'white', fontSize: 20, fontWeight: 600, padding: 5 }}>
                                            Total Training categories
                                        </Typography>
                                        <Typography style={{ margin: 20, fontSize: 24, fontWeight: 500 }}>
                                            {counts?.trainingCategory ? counts?.trainingCategory : 0}
                                        </Typography>
                                        <center>
                                            <Button variant="contained" style={{ marginBottom: 10 }} onClick={() => { navigate("/admin/training-category"); setActiveList("/admin/training-category") }} >View</Button>
                                        </center>
                                    </CardContent>
                                </Card>
                                <Card sx={{ minWidth: 275 }} style={{ backgroundColor: 'whitesmoke', textAlign: 'center', marginTop: 20, marginBottom: 20, marginLeft: 10 }} >
                                    <CardContent style={{ padding: 0 }}>
                                        <Typography style={{ backgroundColor: '#1d5a96', color: 'white', fontSize: 20, fontWeight: 600, padding: 5 }}>
                                            Total Training Modules
                                        </Typography>
                                        <Typography style={{ margin: 20, fontSize: 24, fontWeight: 500 }}>
                                            {counts?.trainingModul ? counts?.trainingModul : 0}
                                        </Typography>
                                        <center>
                                            <Button variant="contained" style={{ marginBottom: 10 }} onClick={() => { navigate("/admin/training-module"); setActiveList("/admin/training-module") }} >View</Button>
                                        </center>
                                    </CardContent>
                                </Card>
                            </div>
                        </Box>
                </div>
            }

            


        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    main: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: 'column',
        marginTop: 30
    },
    card: {
        width: '1200px',
        borderRadius: '20px !important',
        margin: '20px',
        padding: '20px',
    },
    passmodal: {
        width: '350px',
        background: 'white',
        borderRadius: '20px !important',
        margin: '20px',
        padding: '20px',
    },
    profilemodal: {
        width: '1400px',
        background: 'white',
        borderRadius: '20px !important',
        margin: '20px',
        padding: '20px',
    },
    middlePosition: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    input: {
        width: '70%',
        background: 'white',
        marginTop: '20px',
        padding: '5px 15px'
    },
}));

const mapStateToProps = (state) => ({})

const mapDispatchToProps = dispatch => {
    return {
        getDashboardCounts: () => dispatch(Action.getDashboardCounts()),
        setActiveList: (data) => dispatch(Action.setActiveList(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)