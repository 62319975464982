import React from 'react';
import "../Assets/css/Thankyou.css";

export default function Thankyou() {
  return (
<div className="content">
  <h2 className="text_shadows">Thank You</h2>
</div>
  )
}
