import React from 'react'
import { connect } from 'react-redux'

export const AllTrainingReportList = (props) => {
  return (
    <div>AllTrainingReportList</div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AllTrainingReportList)