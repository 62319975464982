import React, { useState, useEffect } from "react";
import { connect } from 'react-redux'
import { makeStyles } from "@material-ui/styles";
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Typography, Button, TablePagination, Box, Paper, CardContent, Modal, IconButton, TextField, FormControl, Card, CircularProgress, Badge, Tooltip } from "@mui/material";
import * as Action from "../../actions/adminAction"
import { useLocation, useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from '@mui/icons-material/Cancel';
import HelpIcon from '@mui/icons-material/Help';
// import DoneIcon from '@mui/icons-material/Done';
import VerifiedIcon from '@mui/icons-material/Verified';
import moment from "moment";
import Videos from "../../Assets/Image/Video.png"
import DOC from "../../Assets/Image/doc.png"
require('../../App.css')

const categories = [
  {
    Name: 'PR',
  },
  {
    Name: 'SN',
  },
  {
    Name: 'GP',
  },
  {
    Name: 'SM',
  },
]

const activities = [
  'Progress',
  'Snacks',
  'Group Photo',
  'Study Material',
  'Notepad and Pen',
  'Attendance Sheet',
  'Feedback Form',
  'Training Outcome',
  'Training Agenda',
  'Study Material Proof',
  'Distribution of Ebook',
  'Beneficiary Book'
]

export const ViewTrainerDocs = ({ userDetails, getCategories, ApproveEvidence, RejectEvidence,getAllCategoryEvidence,FinalAprroveTraining,setActiveList,ApproveTrainingCategory }) => {

  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const Trainer = location.state

  const [openModal, setOpenModal] = useState(false);
  const [rejectModal, setrejectModal] = useState(false)
  const [ApproveMsg, setApproveMsg] = useState(false)
  const [validate,setValidate] = useState()
  const [loader, setLoader] = useState(false)
  const [catloader, setCatLoader] = useState(false)

  const [categories, setcategories] = useState([])
  const [AllCategories,setAllCategories] = useState([])
  const [SelectedCategory, setSelectedCategory] = useState('')
  const [evidenceId, setEvidenceId] = useState('')
  const [moduleId,setModuleId] = useState('')
  const [Panchu,setPanchu] = useState('')
  const [CateG,setCateG] = useState('')
  const [comment, setcomment] = useState('')

  //Category Approval
  const [TrainingModuleGPID,setTrainingModuleGPID] = useState()
  const [CategoryId,setCategoryId] = useState()
  const [GramPanchayatId,setGramPanchayatId] = useState()
  const [openFinal, setopenFinal] = useState(false)



  // console.log("Data", Trainer)
  // console.log("Details", userDetails)

  useEffect(() => {
    getAllCategories()
    getAllEvidenceCategories()
  }, [])

  useEffect( () => {
    if(AllCategories){
      validateApprove()
    }
  },[AllCategories] )

  useEffect( () => {
    if(validate === false ){
      FinalAprroveTrainingData()
    }
  },[validate] )

  const getAllCategories = async () => {
    setCatLoader(true)
    const result = await getCategories({
      "grampanchayat_id": Trainer?.grampanchayat_id,
      "trainingModule_id": Trainer?.trainingModule_id
    })
    if (result) {
      setcategories(result)
      setCatLoader(false)
    }
  }

  const getAllEvidenceCategories = async () => {
    setLoader(true)
    const result = await getAllCategoryEvidence({
      "grampanchayat_id": Trainer?.grampanchayat_id,
      "trainingModule_id": Trainer?.trainingModule_id,
      "trainer_id":Trainer?.trainer_id
    })
    if (result) {
      setAllCategories(result)
      setLoader(false)
    }
  }

  const ApproveImage = async () => {
    let name = userDetails?.firstName + userDetails?.lastName
    const result = await ApproveEvidence({
      approveRejectUser_id: userDetails?._id,
      approveRejectUserName: name,
      updatedBy: userDetails?._id,
      trainerEvidence_id: evidenceId,
      trainingModule_id:moduleId,
      grampanchayat_id:Panchu,
      trainingCategory_id:CateG
    })
    if (result) {
      getAllCategories()
      getAllEvidenceCategories()
    }
  }

  const openReject = (row) => {
    console.log("Reject Now",row)
    setEvidenceId(row?._id)
    setModuleId(row?.trainingModule_id)
    setPanchu(row?.grampanchayat_id)
    setCateG(row?.trainingCategory_id)
    setrejectModal(true)
  }

  const openApprove = (row) => {
    console.log("APprove Now",row)
    setEvidenceId(row?._id)
    setModuleId(row?.trainingModule_id)
    setPanchu(row?.grampanchayat_id)
    setCateG(row?.trainingCategory_id)
    setApproveMsg(true)
  }

  const RejectImage = async () => {
    let name = userDetails?.firstName + userDetails?.lastName
    const result = await RejectEvidence({
      approveRejectComment: comment,
      approveRejectUser_id: userDetails?._id,
      approveRejectUserName: name,
      updatedBy: userDetails?._id,
      trainerEvidence_id: evidenceId,
      trainingModule_id:moduleId,
      grampanchayat_id:Panchu,
      trainingCategory_id:CateG
    })
    if (result) {
      setrejectModal(false)
      getAllCategories()
      getAllEvidenceCategories()
      setcomment('')
    }
  }

  const validateApprove = () => {
    console.log("Validating")
    if(AllCategories.length !== 0 && AllCategories !== 'No data found' ){
      let AllCount = AllCategories.length
      let ApproveCount = 0;

      AllCategories?.map( (item) => {
        if(item?.isApproved){
          console.log("Counting")
          ApproveCount++;
        }
      })  

      if( AllCount === ApproveCount ){
        setValidate(false)
      }else{
        setValidate(true)
      } 
    }else{
      console.log("No Category Found")
      setValidate(true)
    } 
  }

  const FinalAprroveTrainingData = async () => {
    console.log("Training",Trainer)
    const result = await FinalAprroveTraining({
      "trainingsssigntrainers_id": Trainer?._id,
      "trainingModule_id": Trainer?.trainingModule_id,
       "distric_id": Trainer?.distric_id,
       "grampanchayat_id": Trainer?.grampanchayat_id,
      "updatedBy": userDetails?._id
    })

    if (result) {
      console.log("Training Approved", result)
      navigate("/admin/Approved-document")
      setActiveList("/admin/Approved-document")
    }
  }

  const openFinalApproval = (row) => {
    setTrainingModuleGPID(row?._id)
    setGramPanchayatId(row?.grampanchayat_id)
    setCategoryId(row?.trainingCategory_id)
    setopenFinal(true)
  }

  const ApproveCategory = async () => {
    const result = await ApproveTrainingCategory({
      "trainingModuleCatGP_id":TrainingModuleGPID,
      "grampanchayat_id":GramPanchayatId,
      "category_id":CategoryId,
      "updatedBy":userDetails?._id
    })
    if(result){
      console.log("Approving category",result)
      setopenFinal(false);
      setOpenModal(false);
      getAllEvidenceCategories();
      getAllCategories();
      validateApprove();
    }
  }

  return (
    <div className={classes.main}>
      <Card className={classes.card} style={{ backgroundColor: 'whitesmoke' }}>
        <div style={{ backgroundColor: '#1d5a96', display: 'flex', flexDirection: 'row', width: '100%', margin: 0 }}>
          <IconButton style={{ margin: 10, padding: 0 }}>
            <Typography style={{ fontFamily: 'sans-serif', fontWeight: 700, color: 'white', fontSize: 24, backgroundColor: '#1d5a96' }} sx={{
            }} >Trainer Data</Typography>
          </IconButton>
        </div>
        <div>
          <Table>
            <TableRow>
              <TableCell style={{ fontSize: 20 }}>Trainer Name:</TableCell>
              <TableCell style={{ fontSize: 20 }}>{Trainer?.trainerNameAll[0] ? Trainer?.trainerNameAll[0] : '-'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontSize: 20 }}>Mobile</TableCell>
              <TableCell style={{ fontSize: 20 }}>{Trainer?.trainerMobileAll[0] ? Trainer?.trainerMobileAll[0] : '-'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontSize: 20 }}>District Name:</TableCell>
              <TableCell style={{ fontSize: 20 }}>{Trainer?.districtName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontSize: 20 }}>Grampanchayat Name:</TableCell>
              <TableCell style={{ fontSize: 20 }}>{Trainer?.grampanchayatName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontSize: 20 }}>Training Module Name:</TableCell>
              <TableCell style={{ fontSize: 20 }}>{Trainer?.trainingModuleName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontSize: 20 }}>Support Trainer Name:</TableCell>
              <TableCell style={{ fontSize: 20 }}>{Trainer?.trainerNameAll[1] ? Trainer?.trainerNameAll[1] : '-'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontSize: 20 }}>Mobile</TableCell>
              <TableCell style={{ fontSize: 20 }}>{Trainer?.trainerMobileAll[1] ? Trainer?.trainerMobileAll[1] : '-'}</TableCell>
            </TableRow>
            
            <TableRow>
              <TableCell style={{ fontSize: 20 }}>Assignment Date:</TableCell>
              <TableCell style={{ fontSize: 20 }}>{moment(Trainer?.startDate).format("DD MMM YYYY")}</TableCell>
            </TableRow>
          </Table>
          <Typography style={{ margin: 20, fontSize: 30, fontWeight: 600 }}>Categories</Typography>
          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 50, flexWrap: 'wrap', padding: '0px 10%' }}>
            {loader || catloader ?
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress color='primary' />
              </div> : AllCategories?.length > 0 && AllCategories !== 'No data found' ? AllCategories?.map((item) => {
                if(item?.isApproved){
                  let approve = 0;
                  let reject = 0;
                  let unprocess = 0;
                  let catArr = categories!==undefined && categories.length > 0 && categories !== "No data found" && categories.filter(row => row?._id === item?.trainingCategoryName)

                    catArr[0]?.category !== undefined &&
                    catArr[0]?.category.length > 0 &&
                    catArr[0]?.category !== "No data found" &&
                    catArr[0]?.category.map((obj) => {
                      if (obj.status === "approve") {
                        approve++;
                      } else if (obj.status === "reject") {
                        reject++;
                      } else if (obj.status === "unprocess") {
                        unprocess++;
                      }
                    })
                  return (
                    <div className='statusCardApprove' onClick={() => { setOpenModal(!openModal); setSelectedCategory(item) }}>
                      <div>
                        <strong>{item?.trainingCategoryName}</strong>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 10 }}>
                        <div style={{ flex: 1 }}>
                          <Tooltip title="Approved">
                            <Badge color="success"
                              sx={{
                                "& .MuiBadge-badge": {
                                  width: 30,
                                  height: 30,
                                  fontSize:14,
                                  borderRadius: '50%',
                                }
                              }} style={{ margin: 15 }} badgeContent={approve ? approve : '0'} />
                          </Tooltip>
                        </div>
                        <div style={{ flex: 1 }}>
                          <Tooltip title="Rejected">
                            <Badge color="error" sx={{
                              "& .MuiBadge-badge": {
                                width: 30,
                                height: 30,
                                fontSize:14,
                                borderRadius: '50%',
                              }
                            }} style={{ margin: 15 }} badgeContent={reject ? reject : '0'} />
                          </Tooltip>
                        </div>
                        <div style={{ flex: 1 }}>
                          <Tooltip title="Unprocess">
                            <Badge sx={{
                              "& .MuiBadge-badge": {
                                color: "white",
                                width: 30,
                                height: 30,
                                fontSize:14,
                                borderRadius: '50%',
                                backgroundColor: "#e9cd16"
                              }
                            }} style={{ margin: 15 }} badgeContent={unprocess ? unprocess : '0' } />
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  )
                }else{
                  let approve = 0;
                  let reject = 0;
                  let unprocess = 0;
                  let catArr = categories!==undefined && categories.length > 0 && categories !== "No data found" && categories.filter(row => row?._id === item?.trainingCategoryName)

                    catArr[0]?.category !== undefined &&
                    catArr[0]?.category.length > 0 &&
                    catArr[0]?.category !== "No data found" &&
                    catArr[0]?.category.map((obj) => {
                      if (obj.status === "approve") {
                        approve++;
                      } else if (obj.status === "reject") {
                        reject++;
                      } else if (obj.status === "unprocess") {
                        unprocess++;
                      }
                    })
                  return (
                    <div className='statusCard' onClick={() => { setOpenModal(!openModal); setSelectedCategory(item) }}>
                      <div>
                        <strong>{item?.trainingCategoryName}</strong>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 10 }}>
                        <div style={{ flex: 1 }}>
                          <Tooltip title="Approved">
                            <Badge color="success"
                              sx={{
                                "& .MuiBadge-badge": {
                                  width: 30,
                                  height: 30,
                                  fontSize:14,
                                  borderRadius: '50%',
                                }
                              }} style={{ margin: 15 }} badgeContent={approve ? approve : '0'} />
                          </Tooltip>
                        </div>
                        <div style={{ flex: 1 }}>
                          <Tooltip title="Rejected">
                            <Badge color="error" sx={{
                              "& .MuiBadge-badge": {
                                width: 30,
                                height: 30,
                                fontSize:14,
                                borderRadius: '50%',
                              }
                            }} style={{ margin: 15 }} badgeContent={reject ? reject : '0'} />
                          </Tooltip>
                        </div>
                        <div style={{ flex: 1 }}>
                          <Tooltip title="Unprocess">
                            <Badge sx={{
                              "& .MuiBadge-badge": {
                                color: "white",
                                width: 30,
                                height: 30,
                                fontSize:14,
                                borderRadius: '50%',
                                backgroundColor: "#e9cd16"
                              }
                            }} style={{ margin: 15 }} badgeContent={unprocess ? unprocess : '0' } />
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  )
                }
              }) : 'No Categories found'}
          </div>
        </div>
        <center>
          <Button variant="contained" style={{ margin: 20 }} onClick={() => navigate(-1)}>Back</Button>
          {loader ? <CircularProgress variant="primary" /> :           <Button variant="contained" style={{ marginLeft: 5 }} onClick={() => { getAllEvidenceCategories();getAllCategories() }} >Reload</Button> }

          {/* <Button variant="contained" style={{ marginLeft:5 }} disabled={ validate } >Final Approval</Button> */}
        </center>
      </Card>

      <Modal
        className={classes.middlePosition}
        open={openModal}
        onClose={(e) => {
          e.preventDefault();
          setOpenModal(!openModal);
        }}
      >
        <Paper className={classes.profilemodal} style={{ marginTop: 70 }}>
          <Stack direction="row" justifyContent="space-between"
            alignItems="center" spacing={2}>
            <Stack direction="column">
              <Typography variant='h6' component='div'>Category Name - {SelectedCategory?.trainingCategoryName}</Typography>
            </Stack>
            <IconButton aria-label="delete" onClick={e => {
              e.preventDefault();
              setOpenModal(!openModal);
            }}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <h3>Documents</h3>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', flexWrap: 'wrap' }}>
            {categories !== 'No data found' && categories.length > 0 ? categories.filter(item => item._id === SelectedCategory?.trainingCategoryName)[0]?.category?.map((row) => {
              if (row?.categoryDoc === 'undefined') {
                if (row?.status === 'approve') {
                  return (
                    <div className='imgContainer'>
                      <div className='statusIcon'>
                        <IconButton size="large" aria-label="status" onClick={e => {
                          console.log("Delete Clicked !!!!!")
                        }}>
                          <VerifiedIcon style={{ fontSize: '40px' }} color="success" />
                          {/* <CancelIcon style={{fontSize:'32px'}} color="error" /> */}
                        </IconButton>
                      </div>
                      <div >
                        {/* <button className='reject' onClick={() => openReject(row)}>
                          Reject
                        </button> */}
                      </div>
                      <div className='img'>
                        <a target="_blank" href={row?.documentUrl}><img src={Videos} style={{ height: '100%', width: '100%' }} /></a>
                      </div>
                    </div>
                  )
                }
                else if (row?.status === 'reject') {
                  return (
                    <div className='imgContainer'>
                      <div className='statusIcon'>
                        <IconButton size="large" aria-label="status" onClick={e => {
                          console.log("Delete Clicked !!!!!")
                        }}>
                          {/* <VerifiedIcon style={{fontSize:'32px'}} color="success" /> */}
                          <CancelIcon style={{ fontSize: '40px' }} color="error" />
                        </IconButton>
                      </div>
                      <div >
                        <button className='approve' onClick={() => openApprove(row)} >
                          Approve
                        </button>
                      </div>
                      {/* <div >
                        <button className='reject' onClick={() => openReject(row)}>
                          Reject
                        </button>
                      </div> */}
                      <div className='img'>
                        <a target="_blank" href={row?.documentUrl}><img src={Videos} style={{ height: '100%', width: '100%' }} /></a>
                      </div>
                    </div>
                  )
                }
                else {
                  return (
                    <div className='imgContainer'>
                      <div >
                        <div className='statusIcon'>
                          <IconButton size="large" aria-label="status" onClick={e => {
                            console.log("Delete Clicked !!!!!")
                          }}>
                            {/* <VerifiedIcon style={{fontSize:'32px'}} color="success" /> */}
                            <HelpIcon style={{ fontSize: '40px' }} sx={{ color: '#e9cd16' }} />
                          </IconButton>
                        </div>
                        <button className='approve' onClick={() => openApprove(row)} >
                          Approve
                        </button>
                      </div>
                      <div >
                        <button className='reject' onClick={() => openReject(row)}>
                          Reject
                        </button>
                      </div>
                      <div className='img'>
                        <a target="_blank" href={row?.documentUrl}><img src={Videos} style={{ height: '100%', width: '100%' }} /></a>
                      </div>
                    </div>
                  )
                }
              } else if (row?.categoryDoc.split('/')[0] === 'video') {
                if (row?.status === 'approve') {
                  return (
                    <div className='imgContainer'>
                      <div className='statusIcon'>
                        <IconButton size="large" aria-label="status" onClick={e => {
                          console.log("Delete Clicked !!!!!")
                        }}>
                          <VerifiedIcon style={{ fontSize: '40px' }} color="success" />
                          {/* <CancelIcon style={{fontSize:'32px'}} color="error" /> */}
                        </IconButton>
                      </div>
                      <div >
                        {/* <button className='reject' onClick={() => openReject(row)}>
                          Reject
                        </button> */}
                      </div>
                      <div className='img'>
                        <a target="_blank" href={row?.documentUrl}><img src={Videos} style={{ height: '100%', width: '100%' }} /></a>
                      </div>
                    </div>
                  )
                }
                else if (row?.status === 'reject') {
                  return (
                    <div className='imgContainer'>
                      <div className='statusIcon'>
                        <IconButton size="large" aria-label="status" onClick={e => {
                          console.log("Delete Clicked !!!!!")
                        }}>
                          {/* <VerifiedIcon style={{fontSize:'32px'}} color="success" /> */}
                          <CancelIcon style={{ fontSize: '40px' }} color="error" />
                        </IconButton>
                      </div>
                      <div >
                        <button className='approve' onClick={() => openApprove(row)} >
                          Approve
                        </button>
                      </div>
                      {/* <div >
                        <button className='reject' onClick={() => openReject(row)}>
                          Reject
                        </button>
                      </div> */}
                      <div className='img'>
                        <a target="_blank" href={row?.documentUrl}><img src={Videos} style={{ height: '100%', width: '100%' }} /></a>
                      </div>
                    </div>
                  )
                }
                else {
                  return (
                    <div className='imgContainer'>
                      <div >
                        <div className='statusIcon'>
                          <IconButton size="large" aria-label="status" onClick={e => {
                            console.log("Delete Clicked !!!!!")
                          }}>
                            {/* <VerifiedIcon style={{fontSize:'32px'}} color="success" /> */}
                            <HelpIcon style={{ fontSize: '40px' }} sx={{ color: '#e9cd16' }} />
                          </IconButton>
                        </div>
                        <button className='approve' onClick={() => openApprove(row)} >
                          Approve
                        </button>
                      </div>
                      <div >
                        <button className='reject' onClick={() => openReject(row)}>
                          Reject
                        </button>
                      </div>
                      <div className='img'>
                        <a target="_blank" href={row?.documentUrl}><img src={Videos} style={{ height: '100%', width: '100%' }} /></a>
                      </div>
                    </div>
                  )
                }
              } else if (row?.categoryDoc.split('/')[0] === 'image') {
                if (row?.status === 'approve') {
                  return (
                    <div className='imgContainer'>
                      <div className='statusIcon'>
                        <IconButton size="large" aria-label="status" onClick={e => {
                          console.log("Delete Clicked !!!!!")
                        }}>
                          <VerifiedIcon style={{ fontSize: '40px' }} color="success" />
                          {/* <CancelIcon style={{fontSize:'32px'}} color="error" /> */}
                        </IconButton>
                      </div>
                      <div >
                        {/* <button className='reject' onClick={() => openReject(row)}>
                          Reject
                        </button> */}
                      </div>
                      <div className='img'>
                        <a target="_blank" href={row?.documentUrl}><img src={row?.documentUrl} style={{ height: '100%', width: '100%' }} /></a>
                      </div>
                    </div>
                  )
                }
                else if (row?.status === 'reject') {
                  return (
                    <div className='imgContainer'>
                      <div className='statusIcon'>
                        <IconButton size="large" aria-label="status" onClick={e => {
                          console.log("Delete Clicked !!!!!")
                        }}>
                          {/* <VerifiedIcon style={{fontSize:'32px'}} color="success" /> */}
                          <CancelIcon style={{ fontSize: '40px' }} color="error" />
                        </IconButton>
                      </div>
                      <div >
                        <button className='approve' onClick={() => openApprove(row)} >
                          Approve
                        </button>
                      </div>
                      {/* <div >
                        <button className='reject' onClick={() => openReject(row)}>
                          Reject
                        </button>
                      </div> */}
                      <div className='img'>
                        <a target="_blank" href={row?.documentUrl}><img src={row?.documentUrl} style={{ height: '100%', width: '100%' }} /></a>
                      </div>
                    </div>
                  )
                }
                else {
                  return (
                    <div className='imgContainer'>
                      <div >
                        <div className='statusIcon'>
                          <IconButton size="large" aria-label="status" onClick={e => {
                            console.log("Delete Clicked !!!!!")
                          }}>
                            {/* <VerifiedIcon style={{fontSize:'32px'}} color="success" /> */}
                            <HelpIcon style={{ fontSize: '40px' }} sx={{ color: '#e9cd16' }} />
                          </IconButton>
                        </div>
                        <button className='approve' onClick={() => openApprove(row)} >
                          Approve
                        </button>
                      </div>
                      <div >
                        <button className='reject' onClick={() => openReject(row)}>
                          Reject
                        </button>
                      </div>
                      <div className='img'>
                        <a target="_blank" href={row?.documentUrl}><img src={row?.documentUrl} style={{ height: '100%', width: '100%' }} /></a>
                      </div>
                    </div>
                  )
                }
              } else {
                if (row?.status === 'approve') {
                  return (
                    <div className='imgContainer'>
                      <div className='statusIcon'>
                        <IconButton size="large" aria-label="status" onClick={e => {
                          console.log("Delete Clicked !!!!!")
                        }}>
                          <VerifiedIcon style={{ fontSize: '40px' }} color="success" />
                          {/* <CancelIcon style={{fontSize:'32px'}} color="error" /> */}
                        </IconButton>
                      </div>
                      <div >
                        {/* <button className='reject' onClick={() => openReject(row)}>
                          Reject
                        </button> */}
                      </div>
                      <div className='img'>
                        <a target="_blank" href={row?.documentUrl}><img src={DOC} style={{ height: '100%', width: '100%' }} /></a>
                      </div>
                    </div>
                  )
                }
                else if (row?.status === 'reject') {
                  return (
                    <div className='imgContainer'>
                      <div className='statusIcon'>
                        <IconButton size="large" aria-label="status" onClick={e => {
                          console.log("Delete Clicked !!!!!")
                        }}>
                          {/* <VerifiedIcon style={{fontSize:'32px'}} color="success" /> */}
                          <CancelIcon style={{ fontSize: '40px' }} color="error" />
                        </IconButton>
                      </div>
                      <div >
                        <button className='approve' onClick={() => openApprove(row)} >
                          Approve
                        </button>
                      </div>
                      {/* <div >
                        <button className='reject' onClick={() => openReject(row)}>
                          Reject
                        </button>
                      </div> */}
                      <div className='img'>
                        <a target="_blank" href={row?.documentUrl}><img src={DOC} style={{ height: '100%', width: '100%' }} /></a>
                      </div>
                    </div>
                  )
                }
                else {
                  return (
                    <div className='imgContainer'>
                      <div >
                        <div className='statusIcon'>
                          <IconButton size="large" aria-label="status" onClick={e => {
                            console.log("Delete Clicked !!!!!")
                          }}>
                            {/* <VerifiedIcon style={{fontSize:'32px'}} color="success" /> */}
                            <HelpIcon style={{ fontSize: '40px' }} sx={{ color: '#e9cd16' }} />
                          </IconButton>
                        </div>
                        <button className='approve' onClick={() => openApprove(row)} >
                          Approve
                        </button>
                      </div>
                      <div >
                        <button className='reject' onClick={() => openReject(row)}>
                          Reject
                        </button>
                      </div>
                      <div className='img'>
                        <a target="_blank" href={row?.documentUrl}><img src={DOC} style={{ height: '100%', width: '100%' }} /></a>
                      </div>
                    </div>
                  )
                }
              }
            }) : 'No Images Available'}
          </div>
          <div style={{ marginTop:20,float:'right' }}>
            { SelectedCategory?.isApproved || categories === 'No data found' ? null : <Button variant="contained" disabled={   categories?.filter(item => item._id === SelectedCategory?.trainingCategoryName)[0]?.category.length === undefined }  onClick={ () => openFinalApproval(SelectedCategory) } >Approve Category</Button> }
          </div>
        </Paper>
      </Modal>

      <Modal
        className={classes.middlePosition}
        open={rejectModal}
        onClose={(e) => {
          e.preventDefault();
          setrejectModal(false)
        }}
      >
        <Paper className={classes.passmodal}>
          <Stack direction="row" justifyContent="space-between"
            alignItems="center" spacing={2}>
            <Stack direction="column">
              <Typography variant='h6' component='div'>Reject Comment</Typography>
            </Stack>
            <IconButton aria-label="delete" onClick={e => {
              e.preventDefault();
              setrejectModal(false);
            }}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10, flexDirection: 'column' }}>
            <TextField
              label="Reject Comment"
              style={{ width: 300 }}
              value={comment}
              onChange={(e) => setcomment(e.target.value)}
            />
            <Button style={{ margin: 10 }} variant="contained" disabled={comment.length === 0} onClick={() => RejectImage()}>Submit</Button>
          </div>
        </Paper>
      </Modal>

      <Modal
        className={classes.middlePosition}
        open={ApproveMsg}
        onClose={(e) => {
          e.preventDefault()
          setApproveMsg(false)
        }}
      >
        <Paper className={classes.passmodal}>
          <Stack direction="row" justifyContent="space-between"
            alignItems="center" spacing={2}>
            <Stack direction="column">
              <Typography variant='h6' component='div'>Want to Approve this</Typography>
            </Stack>
            <IconButton aria-label="delete" onClick={e => {
              e.preventDefault();
              setApproveMsg(false);
            }}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop: 10 }}>
            <h3>Do you want to Approve this ?</h3>
            <div style={{ display: 'flex', flexDirection: 'row' }}>

              <Button variant="contained" style={{ backgroundColor: 'green', width: 100, margin: 5 }} onClick={() => {
                setApproveMsg(false);
                ApproveImage()
              }}>Approve</Button>

              <Button variant="contained" style={{ backgroundColor: 'grey', width: 100, margin: 5 }} onClick={() => {
                setApproveMsg(false)
              }}>Cancel</Button>
            </div>
          </div>
        </Paper>
      </Modal>

      <Modal
        className={classes.middlePosition}
        open={openFinal}
        onClose={(e) => {
          e.preventDefault()
          setopenFinal(false)
        }}
      >
        <Paper className={classes.passmodal}>
          <Stack direction="row" justifyContent="space-between"
            alignItems="center" spacing={2}>
            <Stack direction="column">
              <Typography variant='h6' component='div'>Want to Approve this Category</Typography>
            </Stack>
            <IconButton aria-label="delete" onClick={e => {
              e.preventDefault();
              setopenFinal(false);
            }}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop: 10 }}>
            <h3>Do you want to Approve this Category ?</h3>
            <div style={{ display: 'flex', flexDirection: 'row' }}>

              <Button variant="contained" style={{ backgroundColor: 'green', width: 100, margin: 5 }} onClick={() => {
                
                ApproveCategory()
                // FinalAprroveTrainingData()
              }}>Approve</Button>

              <Button variant="contained" style={{ backgroundColor: 'grey', width: 100, margin: 5 }} onClick={() => {
                setopenFinal(false)
              }}>Cancel</Button>
            </div>
          </div>
        </Paper>
      </Modal>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: 'column',
    marginTop: 30
  },
  card: {
    width: '80%',
    borderRadius: '20px !important',
    margin: '20px',
    padding: '20px',
  },
  passmodal: {
    width: '350px',
    background: 'white',
    borderRadius: '20px !important',
    margin: '20px',
    padding: '20px',
  },
  profilemodal: {
    width: '1000px',
    background: 'white',
    borderRadius: '20px !important',
    margin: '20px',
    padding: '20px',
  },
  middlePosition: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  input: {
    width: '70%',
    background: 'white',
    marginTop: '20px',
    padding: '5px 15px'
  },
  image: {
    width: 150,
    height: 150
  },
  imageApprove: {
    backgroundColor: 'green',
    color: 'white'
  },
  imageReject: {
    backgroundColor: 'red',
    color: 'white'
  }
}));

const mapStateToProps = (state) => {
  return {
    userDetails: state.auth.userDetails,

  }
}

const mapDispatchToProps = dispatch => {
  return {
    getCategories: (data) => dispatch(Action.getCategories(data)),
    ApproveEvidence: (data) => dispatch(Action.ApproveEvidence(data)),
    RejectEvidence: (data) => dispatch(Action.RejectEvidence(data)),
    getAllCategoryEvidence: (data) => dispatch(Action.getAllCategoryEvidence(data)),
    FinalAprroveTraining: (data) => dispatch(Action.FinalAprroveTraining(data)),
    setActiveList: (data) => dispatch(Action.setActiveList(data)),
    ApproveTrainingCategory:(data) => dispatch(Action.ApproveTrainingCategory(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewTrainerDocs)