import { connect } from 'react-redux'
import { makeStyles } from "@material-ui/styles";
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Typography, Button, TablePagination, Box, Paper, CircularProgress, TextField, FormControl, MenuItem, InputLabel, Select, Autocomplete, Modal, IconButton } from "@mui/material";
import * as Action from "../../actions/adminAction"
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";

export const BeneficieryListAndCertificates = ({
    userDetails,
    getAllAssignedTrainer,
    getAllDatewiseAssignedTrainer,
    getAllTrainers,
    getAllDistrictData,
    getTalukaWisePanchayat,
    getAllTrainingModuleData,
    getDistrictWiseTalukaData,
    getModuleWiseTrainerList,
    getAllTrainingData,
    getAllTrainingDataByDate,
    getAllTrainingDataByModule
}) => {

    const classes = useStyles()
    const [loader, setLoader] = useState(false)
    const navigate = useNavigate()


    //data variables
    const [city, setCity] = useState("");
    const [taluka, setTaluka] = useState("");
    const [panchayat, setPanchayat] = useState("");
    const [type, setType] = useState('');
    const [ModuleData, setModuleData] = useState([])
    //Error Variables
    const [cityError, setcityError] = useState(false);
    const [TalukaError, setTalukaError] = useState(false);
    const [PanchayatError, setPanchayatError] = useState(false);
    const [typeError, setTypeError] = useState(false);
    const [TalukaData, setTalukaData] = useState([]);
    const [DistrictData, setDistrictData] = useState([]);
    const [GrampanchayatData, setGrampanchayatData] = useState([]);

    //data variables
    const [trainerData, setTrainerData] = useState([])
    const [startDate, setstartDate] = useState(new Date())
    const [endDate, setendDate] = useState(new Date())
    const [searchTrainerError, setsearchTrainerError] = useState(false);
    const [searchTrainer, setsearchTrainer] = useState(null)
    const [userData, setuserData] = useState([])

    // Error variables
    const [dateError, setdateError] = useState(false)
    const [InvalidDate, setInvalidDate] = useState(false)

    //Table Pagination variables
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    //Table Pagination Functions
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    //useEffect
    useEffect(() => {
        getTrainerData()
        getAll()
        getDistrict();
        getModule();
    }, [])

    useEffect(() => {
        if (city) {
            getTaluka()
            setTaluka("")
            setPanchayat("")
        }
    }, [city])

    useEffect(() => {
        if (taluka) {
            getPanchayat()
            setPanchayat("")
        }
    }, [taluka])

    const getDistrict = async () => {
        const result = await getAllDistrictData();
        if (result !== "No data found") {
            setDistrictData(result);
        }
    };

    const getTaluka = async () => {
        setTalukaError(false)
        const result = await getDistrictWiseTalukaData({
            distric_id: city?._id
        })
        if (result !== 'No data found') {
            setTalukaData(result)
            console.log("Taluka list", result)
        } else {
            setTalukaError(true)
        }
    }

    const getPanchayat = async () => {
        setPanchayatError(false)
        const result = await getTalukaWisePanchayat({
            distric_id: city?._id,
            taluka_id: taluka?._id
        })
        if (result !== 'No data found') {
            setGrampanchayatData(result)
        } else {
            setPanchayatError(true)
        }
    }

    const getModule = async () => {
        const result = await getAllTrainingModuleData()
        if (result !== 'No data found') {
            setModuleData(result)
            console.log("ModuleList", result)
        }
    }

    const getAll = async () => {
        const result = await getAllTrainers()
        if (result) {
            console.log("Trainer list", result)
            setuserData(result)
        }
    }

    const validate = () => {
        let IsformValid = true;

        if (!city) {
            IsformValid = false;
            setcityError(true)
        }

        if (!taluka) {
            IsformValid = false;
            setTalukaError(true)
        }

        if (!panchayat) {
            IsformValid = false;
            setPanchayatError(true)
        }

        if (!type) {
            IsformValid = false;
            setTypeError(true)
        }

        return IsformValid;

    }

    const getModuleWiseTrainerData = async () => {
        setcityError(false)
        setTalukaError(false)
        setPanchayatError(false)
        setTypeError(false)
        if (validate()) {
            // setLoader(true)
            const result = await getAllTrainingDataByModule({
                grampanchayat_id: panchayat?._id,
                trainingModuleName: type?.trainingModuleName,
                distric_id:city?._id
            })
            if (result) {
                setTrainerData(result)
                setPage(0)
                setLoader(false)
                console.log("MODULE WISE TRAINER", result)
            }
        }
    }

    //GET Trianer data
    const getTrainerData = async () => {
        setLoader(true)
        const result = await getAllTrainingData()
        if (result !== 'No data fonund') {
            setTrainerData(result)
            setLoader(false)
        } else {
            setLoader(false)
        }
    }

    //Get DateWise Data
    const getDateWise = async () => {
        setLoader(true)
        setdateError(false)
        setInvalidDate(false)
        if (endDate < startDate) {
            console.log("Invalid")
            setLoader(false)
            setInvalidDate(true)
        } else {
            if (startDate !== null && endDate !== null) {
                const result = await getAllTrainingDataByDate({
                    startDate: moment(startDate.$d).format("YYYY-MM-DD"),
                    endDate: moment(endDate.$d).format("YYYY-MM-DD")
                })
                if (result) {
                    setTrainerData(result)
                    setLoader(false)
                } else {
                    setLoader(false)
                }
            } else {
                setLoader(false)
                setdateError(true)
            }

        }

    }

    const getSingleUser = (value) => {
        console.log(value)
        if (value !== null) {
            let arr = trainerData.filter(item => item?.trainer_idAll[0] === value?._id || item?.trainer_idAll[1] === value?._id)
            console.log("USERWISE", arr)
            setTrainerData(arr)
        }
    }

    return (
        <div className={classes.main}>
            <center>
                <Typography style={{ margin: 20, fontSize: 30, fontWeight: 600 }} >All Trainings with Beneficiary list</Typography>
            </center>

            <div style={{ display: "flex", flexDirection: "row", marginBottom: 10, flexWrap: 'wrap' }}>
                <FormControl sx={{ m: 1, marginRight: 1 }}>
                    <InputLabel id="outlined-adornment-Bank">District</InputLabel>
                    <Select
                        style={{ width: 200 }}
                        id="outlined-adornment-Bank"
                        label="District"
                        value={city}
                        onChange={(e) => {
                            setCity(e.target.value);
                        }}
                        MenuProps={{
                            style: {
                                maxHeight: 400,
                            },
                        }}
                        disabled={DistrictData === undefined || DistrictData?.length === 0}
                    >
                        {DistrictData !== undefined && DistrictData.length !== 0 &&
                            DistrictData.map((item) => {
                                return (
                                    <MenuItem key={item?._id} value={item}>
                                        {item?.districtName}
                                    </MenuItem>
                                );
                            })}
                    </Select>
                    {cityError ? (
                        <span style={{ color: "red", fontSize: 15 }}>
                            *Please Select City
                        </span>
                    ) : null}
                </FormControl>
                <FormControl sx={{ m: 1, marginRight: 1 }}>
                    <InputLabel id="outlined-adornment-Bank">Taluka</InputLabel>
                    <Select
                        style={{ width: 200 }}
                        id="outlined-adornment-Bank"
                        label="Taluka"
                        value={taluka}
                        disabled={TalukaData.length === 0}
                        onChange={(e) => {
                            setTaluka(e.target.value);
                        }}
                        MenuProps={{
                            style: {
                                maxHeight: 400,
                            },
                        }}
                    >
                        {TalukaData &&
                            TalukaData.map((item) => {
                                return (
                                    <MenuItem key={item?._id} value={item}>
                                        {item?.talukaName}
                                    </MenuItem>
                                );
                            })}
                    </Select>
                    {TalukaError ? (
                        <span style={{ color: "red", fontSize: 15 }}>
                            *Please Select Taluka
                        </span>
                    ) : null}
                </FormControl>
                <FormControl sx={{ m: 1, marginRight: 1 }}>
                    <InputLabel id="outlined-adornment-Bank">Grampanchayat</InputLabel>
                    <Select
                        style={{ width: 200 }}
                        id="outlined-adornment-month"
                        label="Grampanchayat"
                        value={panchayat}
                        disabled={GrampanchayatData.length === 0}
                        onChange={(e) => {
                            setPanchayat(e.target.value);
                        }}
                        MenuProps={{
                            style: {
                                maxHeight: 400,
                            },
                        }}
                    >
                        {GrampanchayatData &&
                            GrampanchayatData.map((item) => {
                                return (
                                    <MenuItem key={item?._id} value={item}>
                                        {item?.grampanchayatName}
                                    </MenuItem>
                                );
                            })}
                    </Select>
                    {PanchayatError ? (
                        <span style={{ color: "red", fontSize: 15 }}>
                            *Please Select Grampanchyat
                        </span>
                    ) : null}
                </FormControl>
                <FormControl sx={{ m: 1, marginRight: 1 }}>
                    <InputLabel id="outlined-adornment-Bank">Module</InputLabel>
                    <Select
                        style={{ width: 200 }}
                        id="outlined-adornment-month"
                        label="Type"
                        value={type}
                        onChange={(e) => {
                            setType(e.target.value);
                        }}
                        MenuProps={{
                            style: {
                                maxHeight: 400,
                            },
                        }}
                        disabled={ModuleData === undefined || ModuleData.length === 0}
                    >
                        {ModuleData &&
                            ModuleData.map((item) => {
                                return (
                                    <MenuItem key={item?._id} value={item}>
                                        {item?.trainingModuleName}
                                    </MenuItem>
                                );
                            })}
                    </Select>
                    {typeError ? (
                        <span style={{ color: "red", fontSize: 15 }}>
                            *Please Select Training Module
                        </span>
                    ) : null}
                </FormControl>
                <Button
                    sx={{ m: 1 }}
                    variant="contained"
                    style={{ fontSize: 14 }}
                    onClick={() => getModuleWiseTrainerData()}
                >
                    Get Data
                </Button>
            </div>

            <Box style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                <div style={{ margin: 5, display: 'flex', flexDirection: 'column' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Start Date"
                            value={startDate}
                            onChange={(newValue) => {
                                setstartDate(newValue);
                            }}
                            inputFormat={"DD/MM/YYYY"}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </div>
                <div style={{ margin: 5, display: 'flex', flexDirection: 'column' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="End Date"
                            value={endDate}
                            minDate={startDate}
                            onChange={(newValue) => {
                                setendDate(newValue);
                            }}
                            inputFormat={"DD/MM/YYYY"}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </div>
                <div style={{ margin: 5, display: 'flex', flexDirection: 'column' }}>
                    <Button variant='contained' style={{ margin: 5, width: 170, height: 50 }} onClick={() => getDateWise()}>Get Data Datewise</Button>
                    {dateError ? <span style={{ color: 'red' }}>*Please Select Both Dates</span> : null}
                    {InvalidDate ? <span style={{ color: 'red' }}>*End Date cannot be less than Start date</span> : null}

                </div>
                <Button variant='contained' style={{ margin: 10, width: 130, height: 50 }} onClick={() => getTrainerData()} >GET All Data</Button>
            </Box>
            <div style={{ display: "flex", flexDirection: "row", marginTop: 20, marginBottom: 30, justifyContent: 'center', alignItems: 'center' }}>
                <div>
                    <Autocomplete
                        id='Trainer-data'
                        sx={{ width: 300 }}
                        options={userData}
                        autoHighlight
                        disablePortal
                        freeSolo
                        disabled={userData === undefined || userData.length === 0}
                        getOptionLabel={(option) => option?.firstName + " " + option?.lastName + " - " + option?.contactNo}
                        renderInput={(params) => (<TextField {...params} label="Search By Trainer" />)}
                        // value={searchTrainer}
                        onChange={(event, newValue) => {
                            setsearchTrainer(newValue);
                            // getSingleUser(newValue)
                        }}
                    />
                    {searchTrainerError ? <span style={{ color: 'red' }} >*Please Select trainer first</span> : null}
                </div>
                <Button variant="contained" disabled={searchTrainer === null} style={{ margin: 10 }} onClick={() => getSingleUser(searchTrainer)}>Get Trainer's Data</Button>
            </div>
            {
                loader ?
                    <CircularProgress color='primary' sx={{ marginTop: 3 }} />
                    :
                    <Paper sx={{ overflow: 'hidden', marginTop: 3, width: '99%' }}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>SR No.</strong></TableCell>
                                        <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>District Name</strong></TableCell>
                                        <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Taluka Name</strong></TableCell>
                                        <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Grampanachayat Name</strong></TableCell>
                                        <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Trainer Name</strong></TableCell>
                                        <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Support Trainer Name</strong></TableCell>
                                        <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Training Module</strong></TableCell>
                                        <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Assignment Date</strong></TableCell>
                                        <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Shift</strong></TableCell>
                                        <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Status</strong></TableCell>
                                        <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Action</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {trainerData?.length > 0 ? trainerData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                        return (
                                            <TableRow>
                                                <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{index + 1}</TableCell>
                                                <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{row?.districtName}</TableCell>
                                                <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{row?.talukaName}</TableCell>
                                                <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{row?.grampanchayatName}</TableCell>
                                                <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{row?.trainerNameAll[0] ? row?.trainerNameAll[0] : '-'}</TableCell>
                                                <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{row?.trainerNameAll[1] ? row?.trainerNameAll[1] : '-'}</TableCell>
                                                <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{row?.trainingModuleName}</TableCell>
                                                <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{moment(row?.startDate).format("DD MMM YYYY")}</TableCell>
                                                <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{row?.shift ? row?.shift.toUpperCase() : '-'}</TableCell>
                                                <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{row?.isApprovedFinal ? 'Approved' : 'Not Approved'}</TableCell>
                                                <TableCell style={{ textAlign: 'center' }}>
                                                    <Button variant='contained' onClick={() => navigate('/admin/View-Beneficiery', { state: row })} >View List</Button>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }) : null}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            // count={count ? count : 0}
                            count={trainerData !== "No data found" && trainerData?.length > 0 ? trainerData?.length : 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>

            }
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    main: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: 'column',
        marginTop: 30
    },
    card: {
        width: '60%',
        borderRadius: '20px !important',
        margin: '20px',
        padding: '20px',
    },
    passmodal: {
        width: '350px',
        background: 'white',
        borderRadius: '20px !important',
        margin: '20px',
        padding: '20px',
    },
    profilemodal: {
        width: '500px',
        background: 'white',
        borderRadius: '20px !important',
        margin: '20px',
        padding: '20px',
    },
    middlePosition: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    input: {
        width: '70%',
        background: 'white',
        marginTop: '20px',
        padding: '5px 15px'
    },
}));

const mapStateToProps = (state) => {
    return {
        userDetails: state.auth.userDetails,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getAllAssignedTrainer: () => dispatch(Action.getAllAssignedTrainer()),
        getAllDatewiseAssignedTrainer: (data) => dispatch(Action.getAllDatewiseAssignedTrainer(data)),
        getAllTrainers: () => dispatch(Action.getAllTrainers()),
        getAllDistrictData: () => dispatch(Action.getAllDistrictData()),
        getDistrictWiseTalukaData: (data) => dispatch(Action.getDistrictWiseTalukaData(data)),
        getTalukaWisePanchayat: (data) => dispatch(Action.getTalukaWisePanchayat(data)),
        getAllTrainingModuleData: () => dispatch(Action.getAllTrainingModuleData()),
        getModuleWiseTrainerList: (data) => dispatch(Action.getModuleWiseTrainerList(data)),
        getAllTrainingData: () => dispatch(Action.getAllTrainingData()),
        getAllTrainingDataByModule: (data) => dispatch(Action.getAllTrainingDataByModule(data)),
        getAllTrainingDataByDate: (data) => dispatch(Action.getAllTrainingDataByDate(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BeneficieryListAndCertificates)