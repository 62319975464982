
// export const BASE_URL = 'https://gsdapi.foxberry.link/v1';
//  export const BASE_URL_FOXBERRY =  'https://smgagriapi.mahareratraining.com/v1';
//  export const BASE_URL = 'https://smgagriapi.mahareratraining.com/v1';
//  export const BASE_URL_FOXBERRY =  'http://localhost:7000/v1';
//  export const BASE_URL = 'http://localhost:7000/v1';
 export const BASE_URL_FOXBERRY =  'https://gsdapi.foxberry.link/v1';
 export const BASE_URL = 'https://gsdapi.foxberry.link/v1';



