import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Button,
  TablePagination,
  Box,
  Paper,
  CardContent,
  Modal,
  IconButton,
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Alert,
  InputAdornment,
  Card,
  CircularProgress,
  Autocomplete
} from "@mui/material";
import * as Action from "../../actions/adminAction";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import PersonAddRounded from "@mui/icons-material/PersonAddRounded";

import React, { useEffect, useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import axios from "axios";
import { Delete, Edit, Visibility } from "@mui/icons-material";
import EditUser from "./EditUser";

const data = [
  {
    PanchyatName: "ADEGAON",
    description: "Implementing Bhujal Yojana",
    TalukaName: "Bhor",
    DistrictName: "Pune",
    SarpanchName: "XYZ",
    GramsevakName: "auyda",
  },
];

export const ListUsers = ({ setActiveList, setEditUser }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false)
  //data variable
  const [userData, setuserData] = useState([]);
  //Table Pagination variables
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [opendeleteuser, setopendeleteuser] = useState();
  const [ViewData, setViewData] = useState(false);
  const [ViewUser, setViewUser] = useState(false);
  const [deleteid, setdeleteid] = useState();
  const [UserType, setUserType] = useState("admin");

    const [searchTrainerError, setsearchTrainerError] = useState(false);
    const [searchTrainer, setsearchTrainer] = useState('')

  //Table Pagination Functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const User = [
    {
      id: 1,
      label: "admin",
      key: 'admin'
    },
    {
      id: 2,
      label: "district coordinator",
      key: 'dc'
    },
    {
      id: 3,
      label: "trainer",
      key: 'trainer'
    },
    {
      id: 4,
      label: "gramsevak",
      key: 'gramsevak'
    },
    {
      id: 5,
      label: "sarpanch",
      key: 'sarpanch'
    },
    {
      id: 6,
      label: "beneficiary",
      key: 'beneficiary'
    },
    {
      id: 7,
      label: "quality checker",
      key: 'qc'
    },
    {
      id: 8,
      label: "client",
      key: 'client'
    },
  ];

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getUserList = async () => {
    setLoader(true)
    await axios
      .post(process.env.REACT_APP_BASE_URL+"/user/getuserlist", {
        userType: `${UserType}`,
      })
      .then((responce) => {
        console.log("responce from getUserList", responce.data);
        if (responce.data !== "No data found") {
          setuserData(responce.data);
          setLoader(false)
        } else if (responce.data == "No data found") {
          setuserData([]);
          setLoader(false)
        }
      })
      .catch((err) => {
        console.log("error in getUserList", err);
      });
  };

  const openDelete = (row) => {
    setdeleteid(row._id);
    setopendeleteuser(true);
  };
  const openView = (row) => {
    setViewData(row);
    console.log("--value of row----", row);
    setViewUser(true);
  };
  const Deleteuser = async () => {
    console.log("deleteid", deleteid);
    const user_id = deleteid;
    axios
      .put(process.env.REACT_APP_BASE_URL+"/user/deleteuser", { user_id })
      .then((responce) => {
        console.log("responce from Deleteuser", responce.data);
        getUserList()
      })
      .catch((err) => {
        console.log("error in Deleteuser", err);
      });
  };
  useEffect(() => {
    getUserList();
  }, [UserType]);

  useEffect( () => {
    if(searchTrainer === null || searchTrainer === undefined){
      getUserList()
    }
  },[searchTrainer])

  console.log(searchTrainer)

  const getSingleUser = (value) => {
    console.log(value)
    let arr = userData.filter( item => item?._id === value?._id )
    console.log(arr)
    setuserData(arr)
  }

  return (
    <div className={classes.main}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",

          justifyContent: "space-between",
        }}
      >
        <Box sx={{ flex: 1 }}>
          <h1> {UserType ? UserType : 'admin'} list</h1>
        </Box>
        <Box sx={{ marginBottom: 2 }}>
          <FormControl>
            <InputLabel id="outlined-adornment-bank">User-Type</InputLabel>
            <Select
              style={{ width: 350 }}
              id="outlined-adornment-bank"
              label="User-Type"
              value={UserType}
              onChange={(e) => setUserType(e.target.value)}
              MenuProps={{
                style: {
                  maxHeight: 400,
                },
              }}
            >
              {User !== "No data  found" && User.length > 0
                ? User.map((option) => (
                  <MenuItem key={option.id} value={option.key}>
                    {option.label}
                  </MenuItem>
                ))
                : null}
            </Select>
            {/* {lnameError ? (
            <span style={{ color: "red", fontSize: 15 }}>
              *Please select user type
            </span>
          ) : null} */}
          </FormControl>
        </Box>
        <Box sx={{ ml: 5 }}>
          <Button
            variant="contained"
            onClick={() => {
              navigate("/admin/add-user");
              setActiveList("/admin/add-user");
            }}
          >
            <PersonAddRounded />
            Add User
          </Button>
        </Box>
      </div>

      {loader ? <CircularProgress color="primary" /> :
        <div>
          <div style={{ display: "flex", flexDirection: "row",marginTop:20,marginBottom: 30,justifyContent:'center',alignItems:'center' }}>
                <div>
                    <Autocomplete
                        id='Trainer-data'
                        sx={{ width: 300 }}
                        options={userData}
                        autoHighlight
                        disablePortal
                        freeSolo
                        getOptionLabel={(option) => option?.firstName + " " + option?.lastName + " - " + option?.contactNo}
                        renderInput={(params) => (<TextField {...params} label="Search user" />)}
                        // value={searchTrainer}
                        onChange={(event, newValue) => {
                            setsearchTrainer(newValue);
                            getSingleUser(newValue)
                        }}
                    />
                    { searchTrainerError ? <span style={{ color:'red' }} >*Please Select trainer first</span> : null  }
                </div>
            </div>
          <Paper sx={{ overflow: "hidden" }}>
            <TableContainer>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>SR No.</strong>
                  </TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>First Name</strong>
                  </TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>last Name</strong>
                  </TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>Contact no</strong>
                  </TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>User Type</strong>
                  </TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                    <strong>Action</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userData?.length > 0 ? (
                  userData
                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow>
                          <TableCell style={{ fontSize: 14, textAlign: "center" }}>
                            {index + 1}
                          </TableCell>
                          <TableCell style={{ fontSize: 14, textAlign: "center" }}>
                            {row?.firstName}
                          </TableCell>
                          <TableCell style={{ fontSize: 14, textAlign: "center" }}>
                            {row?.lastName}
                          </TableCell>
                          <TableCell style={{ fontSize: 14, textAlign: "center" }}>
                            {row?.contactNo}
                          </TableCell>
                          <TableCell style={{ fontSize: 14, textAlign: "center" }}>
                            {row?.userType[0]}
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            <Button
                              variant="contained"
                              style={{ margin: 5 }}
                              onClick={() => openView(row)}
                            >
                              <Visibility style={{ marginRight: 5 }} />
                              {/* View */}
                            </Button>
                            {/* <Button
                          variant="contained"
                          style={{ margin: 5 }}
                          onClick={async () => {
                            await setEditUser(row);
                            navigate("/admin/edit-user");
                            setActiveList("/admin/edit-user");
                          }}
                        >
                          <Edit style={{ marginRight: 5 }} />
                          {/* Edit 
                        </Button> */}
                            <Button
                              onClick={() => {
                                openDelete(row);
                              }}
                              variant="contained"
                              style={{ margin: 5 }}
                            >
                              <Delete style={{ marginRight: 5 }} />
                              {/* Delete */}
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })
                ) : (
                  <TableRow>NO Data Found</TableRow>
                )}
              </TableBody>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              // count={count ? count : 0}
              count={userData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      }

      {/* delete model */}
      <Modal
        className={classes.middlePosition}
        open={opendeleteuser}
        onClose={(e) => {
          e.preventDefault();
          setopendeleteuser(false);
        }}
      >
        <Paper className={classes.profilemodal}>
          <Stack
            //direction="row"
            // justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Paper>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Stack direction="column">
                  <Typography
                    variant="h6"
                    justifyContent={"center"}
                    component="div"
                  >
                    Are You Sure You Want To Delete ?
                  </Typography>
                </Stack>
              </Stack>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                  margin: "21px",
                }}
              >
                <Button
                  variant="contained"
                  style={{ margin: 10, backgroundColor: "green" }}
                  // endIcon={<SendIcon />}
                  onClick={(e) => {
                    e.preventDefault();
                    // EdithandleSubmit();
                    setopendeleteuser(false);
                  }}
                >
                  Cancle
                </Button>
                <Button
                  variant="contained"
                  style={{ margin: 10, backgroundColor: "red" }}
                  // endIcon={<SendIcon />}
                  onClick={(e) => {
                    e.preventDefault();
                    Deleteuser();
                    setopendeleteuser(false);
                    // DeleteTaluka()
                    // setDeleteTaluka(false)
                  }}
                >
                  <Delete style={{ marginRight: 5 }} />
                  Delete
                </Button>
              </div>
            </Paper>
          </Stack>
        </Paper>
      </Modal>

      {/* view model */}
      <Modal
        className={classes.middlePosition}
        open={ViewUser}
        onClose={(e) => {
          e.preventDefault();
          setViewUser(false);
        }}
      >
        <Paper className={classes.profilemodal}>
          <Stack alignItems="center" spacing={2}>
            <Stack>
              <Typography variant="h6" component="div">
                View User
              </Typography>

              {/* <IconButton
                aria-label="delete"
                onClick={(e) => {
                  e.preventDefault();
                  setViewUser(false);
                }}
              >
                <CloseIcon />
              </IconButton> */}
            </Stack>
            <Stack>
              <Card
                className={classes.card}
                style={{ backgroundColor: "whitesmoke" }}
              >
                <div
                  style={{
                    backgroundColor: "#1d5a96",
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    margin: 0,
                  }}
                >
                  <IconButton style={{ margin: 10, padding: 0 }}>
                    <Typography
                      style={{
                        fontFamily: "sans-serif",
                        fontWeight: 700,
                        color: "white",
                        fontSize: 24,
                        backgroundColor: "#1d5a96",
                      }}
                      sx={{}}
                    >
                      User Data
                    </Typography>
                  </IconButton>
                </div>
                <div>
                  <Table>
                    <TableRow>
                      <TableCell style={{ fontSize: 15 }}>User Name</TableCell>
                      <TableCell style={{ fontSize: 15 }}>
                        {ViewData?.firstName}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontSize: 15 }}>
                        Contact Number
                      </TableCell>
                      <TableCell style={{ fontSize: 15 }}>
                        {ViewData?.contactNo}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontSize: 15 }}>email id</TableCell>
                      <TableCell style={{ fontSize: 15 }}>
                        {ViewData?.emailId ? ViewData?.emailId : '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontSize: 15 }}>
                        current Grampanchayat
                      </TableCell>
                      <TableCell style={{ fontSize: 15 }}>
                        {ViewData?.currentAddressGrampanchayat}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontSize: 15 }}>
                        current Taluka Name
                      </TableCell>
                      <TableCell style={{ fontSize: 15 }}>
                        {ViewData?.currentAddressTaluka}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontSize: 15 }}>
                        preferred Grampanchayat Name
                      </TableCell>
                      <TableCell style={{ fontSize: 15 }}>
                        {ViewData?.preferredGrampanchayatName}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontSize: 15 }}>
                        preferred Taluka Name
                      </TableCell>
                      <TableCell style={{ fontSize: 15 }}>
                        {ViewData?.preferredTalukaName}
                      </TableCell>
                    </TableRow>
                  </Table>
                </div>
                <center>
                  <Button
                    variant="contained"
                    style={{ margin: 20 }}
                    onClick={() => setViewUser(false)}
                  >
                    Back
                  </Button>
                </center>
              </Card>
            </Stack>
          </Stack>
        </Paper>
      </Modal>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: 30,
  },
  middlePosition: {
    marginTop: 100,

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  profilemodal: {
    // width: "1000px",
    width: "50%",
    background: "white",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
}));

const mapStateToProps = (state) => {
  return {
    userDetails: state.auth.userDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setActiveList: (data) => dispatch(Action.setActiveList(data)),
    setEditUser: (data) => dispatch(Action.setEditUser(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListUsers);
