import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Button,
  TablePagination,
  Box,
  Paper,
  CardContent,
  Modal,
  IconButton,
  TextField,
  FormControl,
  Card,
  Input,
} from "@mui/material";
import { CircularProgress } from "@material-ui/core";
import * as Action from "../../actions/adminAction";
import Image1 from "../../Assets/Image/satara_Mandave.JPG";
import Image2 from "../../Assets/Image/satara_Mandave_2.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";
import { BASE_URL } from "../../utils/urls";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
// import FormControl from '@mui/material/FormControl';
import Select from "@mui/material/Select";
import * as XLSX from "xlsx";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
// import * as FileSaver from 'file-saver';
// import XLSX from 'sheetjs-style'
require("../../App.css");

// import Paper from '@mui/material/Paper';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TablePagination from '@mui/material/TablePagination';
// import TableRow from '@mui/material/TableRow';

const columns = [
  { id: "beneficiary_Id", label: "Beneficiary_Id", minWidth: 100 },
  { id: "name", label: "Name", minWidth: 170 },
  {
    id: "mobile",
    label: "Mobile",
    minWidth: 170,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "action",
    label: "Action",
  },
];

function createData(beneficiary_Id, name, mobile, action) {
  // const density = population / size;
  return { beneficiary_Id, name, mobile, action };
}

const rows = [
  createData("Abc1212", "Yash", 9657149461, "delete"),
  createData("Abc1212", "Yash", 9657149461, "delete"),
];

export const BeneficiaryListDetails = ({ userDetails }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const Trainer = location.state;
  const [gramData, setGramData] = useState();
  const [loader, setLoader] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [assign, setAssign] = useState(false);
  const [ContactList, setContactList] = useState([]);
  const [xcelData, setxcelData] = useState([]);
  const [array, setArray] = useState([]);
  const [err, setErr] = useState([]);
  const [showErr, setShowErr] = useState(false);
  const [Gender, setGender] = React.useState();
  const [name, setName] = React.useState();
  const [mobile, setMobile] = React.useState();
  const [beneficiaryData, setBeneficiaryData] = React.useState([]);
  const [duplicateBeneficiaryData, setDuplicateBeneficiaryData] =
    React.useState([]);
  const [imgloader, setimgloader] = React.useState(false);

  useEffect(() => {
    getGramData();
    getBeneficiaryData();
  }, []);

  const FileUploadError = () => toast.error("Please upload XLSX File Only");
  const DuplicateError = () => toast.error("Mobile number already exist");
  // const excelDataError = ()=> toast.error(err.map((u)=>{
  //     return(
  //         <h1>{u}</h1>
  //     )
  // }))

  const handleChange = (event) => {
    setGender(event.target.value);
  };

  const handleAdd = async () => {
    let urlencoded = new URLSearchParams();
    urlencoded.append("contactNo", mobile);
    urlencoded.append("name", name);
    urlencoded.append("gender", Gender);
    urlencoded.append("trainingsssigntrainer_id", Trainer._id);
    urlencoded.append("trainer_id", Trainer._id);
    urlencoded.append("createdBy", Trainer.createdBy);
    urlencoded.append("grampanchayatName", Trainer.grampanchayatName);
    urlencoded.append("grampanchayat_id", Trainer.grampanchayat_id);
    urlencoded.append("talukaName", Trainer.talukaName);
    urlencoded.append("taluka_id", Trainer.taluka_id);
    urlencoded.append("districtName", Trainer.districtName);
    urlencoded.append("distric_id", Trainer.distric_id);
    urlencoded.append("startDate", Trainer.startDate);
    urlencoded.append("shiftshift", Trainer.shiftshift);
    urlencoded.append("trainingModuleName", Trainer.trainingModuleName);
    urlencoded.append("trainingModule_id", Trainer.trainingModule_id);
    urlencoded.append("trainerMobile", Trainer.trainerMobile);
    urlencoded.append("trainerName", Trainer.trainerName);

    let options = {
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: urlencoded,
      redirect: "follow",
    };
    await fetch(BASE_URL + "/beneficiary/insertbeneficiarytraining", options)
      .then((res) => res.json())
      .then((response) => {
        if (typeof response === "object") {
          getBeneficiaryData();
        } else {
          alert(response);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
    setAssign(false);
  };
  const handleChangeExcel = (e) => {
    setimgloader(true);
    var name = e.target.files[0].name;
    const ext = name.split(".")[1];
    if (ext === "xlsx") {
      const reader = new FileReader();
      reader.onload = (evt) => {
        // evt = on_file_select event
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        // Get ContactList worksheet
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        // Convert array of arrays
        // const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
        const data1 = XLSX.utils.sheet_to_json(wb.Sheets[wsname], {
          raw: false,
        });
        // Update state
        setArray(data1);
        validation(data1);
      };
      reader.readAsBinaryString(e.target.files[0]);
    } else {
      FileUploadError();
      console.log("Extension ERROR");
    }
    document.getElementById("myInput").value = "";
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleModel = () => {
    setName("");
    setMobile("");
    setGender("");
    setAssign(true);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const validation = async (data) => {
    if (data.length > 0) {
      let errorArray = [];
      data.map((row, index) => {
        if (!row?.Gender) {
          errorArray.push(`Row ${index + 2} Enter Gender Name`);
        } else if (
          row?.Gender.toUpperCase().localeCompare("MALE") !== 0 &&
          row?.Gender.toUpperCase().localeCompare("FEMALE") !== 0
        ) {
          errorArray.push(
            `Row ${index + 2}  Gender Name Should be MALE or FEMALE `
          );
        }

        if (!row?.Name) {
          errorArray.push(`Row ${index + 2} Enter Name`);
        } else if (row?.Name.match(/[0-9+@#$&%!~]/)) {
          errorArray.push(`Row ${index + 2} Invalid Name`);
        }

        if (!row?.ContactNo) {
          errorArray.push(`Row ${index + 2} Enter ContactNo `);
        } else if (
          row?.ContactNo.match(/[A-Za-z+@#$&%!~]/) ||
          (row?.ContactNo.length !== 10 && row?.ContactNo.length !== 0)
        ) {
          errorArray.push(`Row ${index + 2} Invalid ContactNo `);
        }
      });
      console.log("Error ===>> ", errorArray);
      setErr(errorArray);

      if (errorArray.length > 0) {
        setShowErr(true);
        DownloadTextFile(errorArray);
        setimgloader(false);
      } else {
        sendData(data);
      }
    }
    setimgloader(false);
  };
  const sendData = async (data) => {
    let payload = [];

    if (data.length > 0) {
      data.map((row) => {
        let obj = {
          districtName: Trainer?.districtName,
          name: row.Name,
          gender: row.Gender,
          contactNo: row.ContactNo,
          trainer_id: userDetails?._id,
          createdBy: userDetails?._id,
          grampanchayatName: Trainer.grampanchayatName,
          grampanchayat_id: Trainer.grampanchayat_id,
          talukaName: Trainer.Grampanchayat.talukaName,
          taluka_id: Trainer.Grampanchayat.taluka_id,
          distric_id: Trainer.distric_id,
          startDate: Trainer.startDate,
          shift: Trainer.shift,
          trainingModuleName: Trainer.trainingModuleName,
          trainingsssigntrainer_id: Trainer._id,
          trainerMobile: userDetails?.contactNo,
          trainerName: userDetails?.firstName + " " + userDetails?.lastName,
          trainingModule_id: Trainer.trainingModule_id,
        };
        payload.push(obj);
      });
    }
    await axios
      .post(BASE_URL + "/beneficiary/insertbulkbeneficiarytraining", payload)
      .then((res) => {
        if (res.data.length > 0) {
          setShowErr(true);
          DownloadTextFile(res.data);
        }
      })
      .catch((err) => {
        console.log("bulk upload api error", err);
      });

    getBeneficiaryData();
  };
  const getBeneficiaryData = async () => {
    // const data1 = userDetails.trainingsssigntrainer_id
    let urlencoded = new URLSearchParams();

    urlencoded.append("trainingsssigntrainer_id", Trainer._id);

    let options = {
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: urlencoded,
      redirect: "follow",
    };

    await fetch(BASE_URL + "/beneficiary/getbeneficiarylist", options)
      .then((res) => res.json())
      .then((res) => {
        if (res !== "No data found" && res.length > 0) {
          setBeneficiaryData(res);
        }
      })
      .catch((err) => {
        console.log("get Beneficiary Data", beneficiaryData);
      });
  };

  const getGramData = () => {
    setLoader(true);
    const url = BASE_URL + "/grampanchayat/getgrampanchayatsinglelist";
    let header = new Headers();
    header.append("Content-Type", "application/x-www-form-urlencoded");
    let urlencoded = new URLSearchParams();
    urlencoded.append("grampanchayat_id", Trainer?.grampanchayat_id);
    const opt = {
      method: "POST",
      headers: header,
      body: urlencoded,
    };
    fetch(url, opt)
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          alert("Error While Getting Grampanchayat Data");
        }
      })
      .then((data) => {
        data !== undefined &&
          data !== null &&
          data !== "No data found" &&
          setGramData(data);
        setLoader(false);
      })
      .catch((err) => {
        alert("Error catched While getting Gram Data");
        console.log("GramData Error", err);
        setLoader(false);
      });
  };
  const DownloadTextFile = (error) => {
    // var row_width = 40;

    var content = "";
    content += "Username\n";

    for (var i = 0; i < error.length; i++) {
      content += error[i];

      content += "\n";
    }
    const element = document.createElement("a");
    const file = new Blob([content], { type: "text/plain;charset=utf-8" });
    element.href = URL.createObjectURL(file);
    element.download = "myFile.txt";
    document.body.appendChild(element);
    element.click();

    // Build a data URI:
    // const uri = "data:application/octet-stream," + encodeURIComponent(content);

    // Click on the file to download
    // You can also do this as a button that has the href pointing to the data URI
    // window.location.href = uri;
  };
  const CustomExcel = () => {
    const customHeadings = { Name: "", Gender: "", ContactNo: "" };
    downloadExcel(customHeadings);
  };
  const downloadExcel = (customHeadings) => {
    let dataSet = [];
    dataSet.push(customHeadings);
    const worksheet = XLSX.utils.json_to_sheet(dataSet);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "UserListReport.xlsx");
  };

  const [openModal, setOpenModal] = useState(false);

  if (loader)
    return (
      <div className={classes.main}>
        <CircularProgress color="primary" />
      </div>
    );

  const deleteBeneficiery = async (row) => {
    // const data1 = userDetails.trainingsssigntrainer_id
    let urlencoded = new URLSearchParams();

    urlencoded.append("trainingBeneficieryId", row._id);

    let options = {
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: urlencoded,
      redirect: "follow",
    };

    await fetch(BASE_URL + "/beneficiary/deleteBeneficiery", options)
      .then((res) => res.json())
      .then((res) => {
        if (res) {
            alert(`Deleted Beneficiery ${res.name}-${res.contactNo} Successfully`)
          getBeneficiaryData();
        }
      })
      .catch((err) => {
        console.log("get Beneficiary Data", beneficiaryData);
      });
  };

  return (
    <div className={classes.main}>
      <ToastContainer />
      <Card className={classes.card} style={{ backgroundColor: "whitesmoke" }}>
        <div
          style={{
            backgroundColor: "#1d5a96",
            display: "flex",
            flexDirection: "row",
            width: "100%",
            margin: 0,
          }}
        >
          <IconButton style={{ margin: 10, padding: 0 }}>
            <Typography
              style={{
                fontFamily: "sans-serif",
                fontWeight: 700,
                color: "white",
                fontSize: 24,
                backgroundColor: "#1d5a96",
              }}
              sx={{}}
            >
              Plan Details
            </Typography>
          </IconButton>
        </div>
        <div>
          <Table>
            <TableRow>
              <TableCell style={{ fontSize: 20 }}>Assignment Date:</TableCell>
              <TableCell style={{ fontSize: 20 }}>
                {Trainer !== undefined && Trainer !== null
                  ? dayjs(Trainer.startDate).format("DD MMM YYYY")
                  : "-"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontSize: 20 }}>Gram Panchayat:</TableCell>
              <TableCell style={{ fontSize: 20 }}>
                {Trainer !== undefined && Trainer !== null
                  ? Trainer.grampanchayatName
                  : "-"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontSize: 20 }}>Module:</TableCell>
              <TableCell style={{ fontSize: 20 }}>
                {Trainer !== undefined && Trainer !== null
                  ? Trainer.trainingModuleName
                  : "-"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontSize: 20 }}>Shift:</TableCell>
              <TableCell style={{ fontSize: 20 }}>
                {Trainer !== undefined && Trainer !== null
                  ? Trainer?.shift
                    ? Trainer?.shift?.toUpperCase()
                    : "-"
                  : "-"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontSize: 20 }}>
                Support Trainer Name:
              </TableCell>
              <TableCell style={{ fontSize: 20 }}>
                {Trainer !== undefined && Trainer !== null
                  ? Trainer.trainerNameAll.filter(
                      (item) =>
                        item.toLowerCase() !==
                        userDetails.firstName.toLowerCase() +
                          " " +
                          userDetails.lastName.toLowerCase()
                    ).length > 0
                    ? Trainer.trainerNameAll.map((item, index) => {
                        if (
                          item.toLowerCase() !==
                          userDetails.firstName.toLowerCase() +
                            " " +
                            userDetails.lastName.toLowerCase()
                        ) {
                          return (
                            item + " (" + Trainer.trainerMobileAll[index] + ")"
                          );
                        }
                      })
                    : "-"
                  : "-"}
              </TableCell>
            </TableRow>
            {/* <TableRow>
                            <TableCell style={{ fontSize: 20 }}>Trainer 1</TableCell>
                            <TableCell style={{ fontSize: 20 }}>{Trainer !== undefined && Trainer !== null && Trainer.trainerNameAll.length>0 ? Trainer.trainerNameAll[0] : "-"}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontSize: 20 }}>Trainer 1 Mobile</TableCell>
                            <TableCell style={{ fontSize: 20 }}>{Trainer !== undefined && Trainer !== null && Trainer.trainerMobileAll.length>0 ? Trainer.trainerMobileAll[0] : "-"}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontSize: 20 }}>Trainer 2</TableCell>
                            <TableCell style={{ fontSize: 20 }}>{Trainer !== undefined && Trainer !== null && Trainer.trainerNameAll.length>1 ? Trainer.trainerNameAll[1] : "-"}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontSize: 20 }}>Trainer 2 Mobile</TableCell>
                            <TableCell style={{ fontSize: 20 }}>{Trainer !== undefined && Trainer !== null && Trainer.trainerMobileAll.length>1 ? Trainer.trainerMobileAll[1] : "-"}</TableCell>
                        </TableRow> */}
            <TableRow>
              <TableCell style={{ fontSize: 20 }}>District:</TableCell>
              <TableCell style={{ fontSize: 20 }}>
                {Trainer !== undefined && Trainer !== null
                  ? Trainer.districtName
                  : "-"}
              </TableCell>
              {/* {/ <TableCell style={{ fontSize: 20 }}>J.K. &#40;8893489833&#41;</TableCell> /} */}
            </TableRow>
            <TableRow>
              <TableCell style={{ fontSize: 20 }}>
                District Co-Ordinator:
              </TableCell>
              <TableCell style={{ fontSize: 20 }}>
                {Trainer?.DC?.firstName && Trainer?.DC?.lastName
                  ? Trainer?.DC?.firstName + " " + Trainer?.DC?.lastName
                  : "-"}
              </TableCell>
              {/* {/ <TableCell style={{ fontSize: 20 }}>J.K. &#40;8893489833&#41;</TableCell> /} */}
            </TableRow>
            <TableRow>
              <TableCell style={{ fontSize: 20 }}>Sarpanch Name:</TableCell>
              {/* {/ <TableCell style={{ fontSize: 20 }}>{gramData !== undefined && gramData.length > 0 && gramData[0]?.sarpanchName !== null && gramData[0]?.sarpanchName !== 'null' ? gramData[0]?.sarpanchName : "-"}</TableCell> /} */}
              <TableCell style={{ fontSize: 20 }}>
                {Trainer?.Grampanchayat?.sarpanchName
                  ? Trainer?.Grampanchayat?.sarpanchName
                  : "-"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontSize: 20 }}>Gram Sevak Name:</TableCell>
              {/* {/ <TableCell style={{ fontSize: 20 }}>{gramData !== undefined && gramData.length > 0 && gramData[0]?.gramsevakhName !== null && gramData[0]?.gramsevakhName !== 'null' ? gramData[0]?.gramsevakhName : "-"}</TableCell> /} */}
              <TableCell style={{ fontSize: 20 }}>
                {Trainer?.Grampanchayat?.gramsevakhName
                  ? Trainer?.Grampanchayat?.gramsevakhName
                  : "-"}
              </TableCell>
            </TableRow>
          </Table>
        </div>

        {/* {/ <div><h1>Beneficiary List</h1></div> /} */}
        <div
          style={{
            backgroundColor: "#1d5a96",
            display: "flex",
            flexDirection: "row",
            width: "100%",
            marginTop: "28px",
          }}
        >
          <IconButton style={{ margin: 10, padding: 0 }}>
            <Typography
              style={{
                fontFamily: "sans-serif",
                fontWeight: 700,
                color: "white",
                fontSize: 24,
                backgroundColor: "#1d5a96",
              }}
              sx={{}}
            >
              Beneficiary List
            </Typography>
          </IconButton>
        </div>
        <div style={{ marginTop: "21px" }}>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button variant="outlined" onClick={() => CustomExcel(array)}>
              Download Excel Data
            </Button>

            <Button variant="outlined" onClick={handleModel}>
              Add single Beneficiary
            </Button>

            <Modal
              className={classes.middlePosition}
              sx={{ marginTop: 18 }}
              open={assign}
              onClose={(e) => {
                e.preventDefault();
                setAssign(false);
              }}
            >
              <Paper
                className={classes.profilemodal}
                style={{ width: "700px" }}
              >
                <Typography style={{ display: "flex", justifyContent: "space-between",alignItems:'center',margin:5 }}>

                    <Typography variant="h6">Add Single Beneficiary</Typography>
                  <IconButton
                    aria-label="delete"
                    onClick={(e) => {
                      e.preventDefault();
                      setAssign(false);
                      // setDate("")
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Typography>

                <Typography
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <TextField
                    id="name"
                    label="Name"
                    variant="outlined"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <TextField
                    id="mobile"
                    label="Mobile"
                    variant="outlined"
                    value={mobile}
                    onChange={(e) => {
                      if (
                        e.target.value.match(/^[0-9]+$/) &&
                        e.target.value.length <= 10
                      ) {
                        setMobile(e.target.value);
                      } else if (e.target.value.length === 0) {
                        setMobile(e.target.value);
                      }
                    }}
                  />

                  <FormControl style={{ width: "25%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Gender
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={Gender}
                      label="Gender"
                      onChange={handleChange}
                    >
                      <MenuItem value={"male"}>male</MenuItem>
                      <MenuItem value={"female"}>female</MenuItem>
                      {/* <MenuItem value={30}>Thirty</MenuItem> */}
                    </Select>
                  </FormControl>
                </Typography>

                <Typography
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "14px",
                  }}
                >
                  <Button variant="outlined" type="submit" onClick={handleAdd}>
                    Add{" "}
                  </Button>
                </Typography>
              </Paper>
            </Modal>
            <Modal
              className={classes.middlePosition}
              sx={{ marginTop: 18 }}
              open={showErr}
              onClose={(e) => {
                e.preventDefault();
                setShowErr(false);
              }}
            >
              <Paper className={classes.profilemodal}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Stack>
                    {/* <Typography variant='h6' >Add Single Beneficiary</Typography> */}
                  </Stack>
                  <IconButton
                    aria-label="delete"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowErr(false);
                      // setDate("")
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Stack>
                <Typography>
                  {/* <Button variant="outlined" marginRight={10} onClick={
                                        
                                    } >Download Error</Button> */}
                  {/* {
                            err.map((u,i)=>{
                                return(
                                    // <h7  >{u}</h7>
                                    <div><p>{u}</p></div>
                                )
                            })
                        } */}
                  <Typography fontWeight="bold">
                    Found Invalid Content In Excel. Please Correct It And
                    Reupload It...
                  </Typography>
                  <p>Please Checkout Downloaded File </p>
                </Typography>
              </Paper>
            </Modal>
            <div
              style={{ display: "flex", justifyContent: "center", margin: 10 }}
            >
              <input
                className={classes.input}
                type="file"
                id="myInput"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                variant="outlined"
                onChange={(e) => {
                  handleChangeExcel(e);
                }}
                style={{ display: "none" }}
              />
              <label htmlFor="myInput">
                {imgloader ? (
                  <CircularProgress color="primary" />
                ) : (
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    style={{
                      borderRadius: "7%",
                      color: "white",
                      backgroundColor: "#1d5a96",
                      fontSize: 17,
                      fontWeight: 400,
                    }}
                    sx={{
                      "&:hover": {
                        backgroundColor: "#f6cc63 !important",
                        fontSize: 17,
                      },
                    }}
                  >
                    Upload Excel
                  </IconButton>
                )}
              </label>
            </div>
          </div>
          <div style={{ marginTop: "32px" }}>
            {/* <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="table-to-xls"
                    fileName="tablexls"
                    sheet="tablexls"
                    buttonText="Export Data to Excel Sheet"
                    /> */}
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Sr.no</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Mobile</TableCell>
                    <TableCell>Gender</TableCell>
                    <TableCell>Action</TableCell>
                    <TableRow />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {beneficiaryData.length > 0
                    ? beneficiaryData
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, i) => {
                          return (
                            <TableRow hover role="checkbox">
                              <TableCell>{i + 1}</TableCell>
                              <TableCell>{row?.name}</TableCell>
                              <TableCell>{row?.contactNo}</TableCell>
                              <TableCell>{row?.gender}</TableCell>
                              <TableCell>
                                <Button
                                  variant="outlined"
                                  onClick={() => deleteBeneficiery(row)}
                                >
                                  DELETE
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })
                    : " NO DATA FOUND "}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={beneficiaryData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>

        <center>
          <Button
            variant="contained"
            style={{ margin: 20 }}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        </center>
      </Card>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: 30,
  },
  card: {
    width: "100%",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  passmodal: {
    width: "350px",
    background: "white",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  profilemodal: {
    width: "1000px",
    background: "white",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  middlePosition: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  input: {
    width: "70%",
    background: "white",
    marginTop: "20px",
    padding: "5px 15px",
  },
  image: {
    width: 150,
    height: 150,
  },
  imageApprove: {
    backgroundColor: "green",
    color: "white",
  },
  imageReject: {
    backgroundColor: "red",
    color: "white",
  },
}));

const mapStateToProps = (state) => {
  return {
    userDetails: state.auth.userDetails,
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BeneficiaryListDetails);
