import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import HomeIcon from "@mui/icons-material/Home";
import PersonAddRoundedIcon from "@mui/icons-material/PersonAddRounded";
import PersonIcon from '@mui/icons-material/Person';
import { makeStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import logo from "../Assets/Image/gsda_logo.jpeg"
import LogoutIcon from '@mui/icons-material/Logout';
import * as Action from '../actions/adminAction';
import * as Auth from '../actions/authActions';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Profile from '../Assets/Image/Profile Admin.png'
import GroupIcon from '@mui/icons-material/Group';
import { Collapse } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import DomainIcon from '@mui/icons-material/Domain';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SettingsIcon from '@mui/icons-material/Settings';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ApprovalIcon from '@mui/icons-material/Approval';

const drawerWidth = 240;

function QualityCheckerLayout(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [Agentopen, setAgentOpen] = React.useState(false);
  const [Bankopen, setBankopen] = React.useState(false);
  const [GSTopen, setGSTopen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Toolbar />
      <Divider />

      <List
        sx={{
          marginTop: 3,
          height: '100vh',
          backgroundColor: '#1d5a96'
        }}
      // style={{ marginTop: 100 }}
      >
        {/* //Assign District */}
        <ListItem
          button
          onClick={() => {
            navigate("/QualityChecker/view-assign-district");
            props.setActiveList("/QualityChecker/view-assign-district")
          }}
          sx={{
            "&:hover": {
              backgroundColor: '#f4c03e',
              border: '1px solid black',
              fontSize: 24
            },
          }}
          style={props.ActiveList === "/QualityChecker/view-assign-district" ? {
            backgroundColor: '#f4c03e',
            border: '1px solid black',
            color: 'white',
            fontSize: 24
          } : {
            color: "white",
          }}
        >
          <div className={classes.back}>
            <ListItemButton>
              <ListItemIcon ><LocationCityIcon style={{ color: 'white', fontSize: 30 }} /></ListItemIcon>
              <Typography style={{ fontSize: 18 }} >All Assign District</Typography>
            </ListItemButton>
          </div>
        </ListItem>



        {/* Home/Rollout Plan */}
        {/* <ListItem
          button
          onClick={() => {
            navigate("/QualityChecker/assign-trainer");
            props.setActiveList("/QualityChecker/assign-trainer")
          }}
          sx={{
            "&:hover": {
              backgroundColor: '#f4c03e',
              border: '1px solid black',
              fontSize: 24
            },
          }}
          style={props.ActiveList === "/QualityChecker/assign-trainer" ? {
            backgroundColor: '#f4c03e',
            border: '1px solid black',
            color: 'white',
            fontSize: 24
          } : {
            color: "white",
          }}
        >
          <div className={classes.back}>
            <ListItemButton>
              <ListItemIcon ><ReceiptIcon style={{ color: 'white', fontSize: 30 }} /></ListItemIcon>
              <Typography style={{ fontSize: 18 }} >All Assign Trainer</Typography>
            </ListItemButton>
          </div>
        </ListItem> */}

        {/* Upload Document  */}
        <ListItem
          button
          onClick={() => {
            props.setActiveList("/QualityChecker/upload-document")
            navigate("/QualityChecker/upload-document")
          }}
          sx={{
            "&:hover": {
              backgroundColor: '#f4c03e',
              border: '1px solid black',
              fontSize: 24
            },
          }}
          style={props.ActiveList === "/QualityChecker/upload-document" ? {
            backgroundColor: '#f4c03e',
            border: '1px solid black',
            color: 'white',
            fontSize: 24
          } : {
            color: "white",
          }}

        >
          <div className={classes.back}>
            <ListItemButton>
              <ListItemIcon > <UploadFileIcon style={{ color: "white", fontSize: 30 }} /></ListItemIcon>
              <Typography style={{ fontSize: 18 }} >Uploaded Document List</Typography>
            </ListItemButton>
          </div>
        </ListItem>

        {/* Approved Training  */}
        <ListItem
          button
          onClick={() => {
            props.setActiveList("/QualityChecker/Approved-document")
            navigate("/QualityChecker/Approved-document")
          }}
          sx={{
            "&:hover": {
              backgroundColor: '#f4c03e',
              border: '1px solid black',
              fontSize: 24
            },
          }}
          style={props.ActiveList === "/QualityChecker/Approved-document" ? {
            backgroundColor: '#f4c03e',
            border: '1px solid black',
            color: 'white',
            fontSize: 24
          } : {
            color: "white",
          }}

        >
          <div className={classes.back}>
            <ListItemButton>
              <ListItemIcon > <ApprovalIcon style={{ color: "white", fontSize: 30 }} /></ListItemIcon>
              <Typography style={{ fontSize: 18 }} >Approved Training Document List</Typography>
            </ListItemButton>
          </div>
        </ListItem>

        {/* Rejected Training  */}
         <ListItem
          button
          onClick={() => {
            props.setActiveList("/QualityChecker/view-trainer-rejected")
            navigate("/QualityChecker/view-trainer-rejected")
          }}
          sx={{
            "&:hover": {
              backgroundColor: '#f4c03e',
              border: '1px solid black',
              fontSize: 24
            },
          }}
          style={props.ActiveList === "/QualityChecker/view-trainer-rejected" ? {
            backgroundColor: '#f4c03e',
            border: '1px solid black',
            color: 'white',
            fontSize: 24
          } : {
            color: "white",
          }}

        >
          <div className={classes.back}>
            <ListItemButton>
              <ListItemIcon ><ReceiptIcon style={{ color: 'white', fontSize: 30 }} /></ListItemIcon>
              <Typography style={{ fontSize: 18 }} >Pending Training List</Typography>
            </ListItemButton>
          </div>
        </ListItem>

        {/* Logout */}
        <ListItem
          button
          onClick={() => {
            props.logout()
            navigate("/")
          }}
          sx={{
            "&:hover": {
              backgroundColor: '#f4c03e',
              border: '1px solid black',
              fontSize: 24
            },
          }}

          style={{ color: 'white' }}

        >
          <div className={classes.back}>
            <ListItemButton>
              <ListItemIcon ><LogoutIcon style={{ color: 'white', fontSize: 30 }} /></ListItemIcon>
              <Typography style={{ fontSize: 18 }} >LogOut</Typography>
            </ListItemButton>
          </div>
        </ListItem>
      </List>
      <Divider />
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  if (props.userDetails === null) {
    navigate("/")
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% -0px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: "white",
          // border: '1px solid #1d5a96',
          zIndex: 1201,
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        <div style={{ flex: 1 }}>
          <Toolbar style={{ width: '100%' }}>
            <IconButton
              color="primary"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <img src={logo} style={{ width: 100, height: 100, padding: 10 }} />
            <Typography style={{ fontSize: 34, color: 'green', fontWeight: 600 }}>GSDA</Typography>
          </Toolbar>
        </div>
        <div style={{ flex: 1 }}>
          <Typography style={{ fontSize: 30, color: 'green', fontWeight: 600, margin: '5px', marginTop: '25px' }}> Quality Checker Dashboard</Typography>
        </div>
        <div >
          <img src={props.userDetails?.profilePicURL ? props.userDetails?.profilePicURL : Profile} style={{ width: 70, height: 60, margin: 20, cursor: 'pointer', border: '1px solid black', borderRadius: '50%' }} onClick={() => {
            navigate('/QualityChecker/Profile'); props.setActiveList('/QualityChecker/Profile')
          }} />
        </div>
      </AppBar>

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: '#1d5a96',
              marginTop: 9
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              "::-webkit-scrollbar": {
                display: "none",
              },
              backgroundColor: '#1d5a96'
            },

          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {/* <Typography paragraph> */}
        <Outlet />
        {/* </Typography> */}
      </Box>
    </Box>
  );
}

QualityCheckerLayout.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
const useStyles = makeStyles((theme) => ({
  back: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "240px",
    minHeight: "70px",
    borderBottomLeftRadius: "30px",
    borderTopLeftRadius: "30px",
  },
  active: {
    backgroundColor: '#f4c03e',
    border: '1px solid black',
    fontSize: 24
  }
}));

const mapStateToProps = (state) => {
  return {
    ActiveList: state.admin.ActiveList,
    userDetails: state.auth.userDetails,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setActiveList: (data) => dispatch(Action.setActiveList(data)),
    logout: () => dispatch(Auth.logout()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QualityCheckerLayout)

