import React from 'react'
import { connect } from 'react-redux'

export const AddSingleBeneficiary = (props) => {
  return (
    <div style={{marginTop:"48px"}}>
        AddSingleBeneficiary
        
        .
        
        </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AddSingleBeneficiary)