import React from 'react';
import PowerBIFrame from '../../Components/PowerBIFrame';
import * as Icon from 'react-bootstrap-icons';

const Analytics = () => {

    const powerBIUrl = "https://app.powerbi.com/view?r=eyJrIjoiMDdmNWYwMjMtM2ZlNi00ZDEyLThhZjEtYTc1M2EwOTUwMmM0IiwidCI6IjI1MmExZDVmLTA3NTQtNDYwYS05YTMyLWE4ZGE4MmZlMmJkNiJ9";

    const powerBIUrl2 = "https://app.powerbi.com/view?r=eyJrIjoiMDdmNWYwMjMtM2ZlNi00ZDEyLThhZjEtYTc1M2EwOTUwMmM0IiwidCI6IjI1MmExZDVmLTA3NTQtNDYwYS05YTMyLWE4ZGE4MmZlMmJkNiJ9&pageName=8286e46583d902e6d06d";

    const powerBIUrl3 = "https://app.powerbi.com/view?r=eyJrIjoiMDdmNWYwMjMtM2ZlNi00ZDEyLThhZjEtYTc1M2EwOTUwMmM0IiwidCI6IjI1MmExZDVmLTA3NTQtNDYwYS05YTMyLWE4ZGE4MmZlMmJkNiJ9&pageName=92a70cea99b3e4c20a0c";

       
    const handleViewFullscreen = (url) => {
      sessionStorage.setItem('iframeUrl', url);
      window.open('/view-fullscreen', '_blank');
    };
     
  return (
    <>
    <div className='Analytics pt-5'>
        <div className='frame'>
          <PowerBIFrame src={powerBIUrl} height="650px"/>
            <div className='frameFooter'>
                  <h5>GSDA Numberic Figures</h5>
                  <p className='mb-0' onClick={()=>handleViewFullscreen(powerBIUrl)}>
                    <Icon.ArrowsFullscreen className='me-2'/>View Fullscreen
                  </p>
            </div>
        </div>
      </div>

      <div className='Analytics pt-5'>
        <div className='frame'>
          <PowerBIFrame src={powerBIUrl2} height="650px"/>
          <div className='frameFooter'>
                  <h5>GSDA Numberic Figures</h5>
                  <p className='mb-0' onClick={()=>handleViewFullscreen(powerBIUrl2)}>
                    <Icon.ArrowsFullscreen className='me-2'/>View Fullscreen
                  </p>
            </div>
        </div>
      </div>

      <div className='Analytics pt-5'>
        <div className='frame'>
          <PowerBIFrame src={powerBIUrl3} height="650px"/>
          <div className='frameFooter'>
                  <h5>GSDA Numberic Figures</h5>
                  <p className='mb-0' onClick={()=>handleViewFullscreen(powerBIUrl3)}>
                    <Icon.ArrowsFullscreen className='me-2'/>View Fullscreen
                  </p>
            </div>
        </div>
      </div>
    </>
      
    
  )
}

export default Analytics;