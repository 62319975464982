import React, { useState, useEffect } from "react";
import { connect } from 'react-redux'
import { makeStyles } from "@material-ui/styles";
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Typography, Button, TablePagination, Box, Paper, CardContent, Modal, IconButton, TextField, FormControl, Card } from "@mui/material";
import * as Action from "../../actions/adminAction"
import Image1 from "../../Assets/Image/satara_Mandave.JPG"
import Image2 from "../../Assets/Image/satara_Mandave_2.jpg"
import { useLocation, useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
require('../../App.css')

const categories = [
  {
    Name: 'PR',
  },
  {
    Name: 'SN',
  },
  {
    Name: 'GP',
  },
  {
    Name: 'SM',
  },
]

const activities = [
  'Progress',
  'Snacks',
  'Group Photo',
  'Study Material',
  'Notepad and Pen',
  'Attendance Sheet',
  'Feedback Form',
  'Training Outcome',
  'Training Agenda',
  'Study Material Proof',
  'Distribution of Ebook',
  'Beneficiary Book'
]

export const ViewApprovedItems = ({ userDetails }) => {

  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const Trainer = location.state

  const [openModal, setOpenModal] = useState(false);

  console.log("Data", Trainer)
  console.log("Details", userDetails)

  return (
    <div className={classes.main}>
      <Card className={classes.card} style={{ backgroundColor: 'whitesmoke' }}>
        <div style={{ backgroundColor: '#1d5a96', display: 'flex', flexDirection: 'row', width: '100%', margin: 0 }}>
          <IconButton style={{ margin: 10, padding: 0 }}>
            <Typography style={{ fontFamily: 'sans-serif', fontWeight: 700, color: 'white', fontSize: 24, backgroundColor: '#1d5a96' }} sx={{
            }} >Trainer Data</Typography>
          </IconButton>
        </div>
        <div>
          <Table>
            <TableRow>
              <TableCell style={{ fontSize: 20 }}>Trainer Name:</TableCell>
              <TableCell style={{ fontSize: 20 }}>{Trainer?.Name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontSize: 20 }}>Trainer Email-Id:</TableCell>
              <TableCell style={{ fontSize: 20 }}>{Trainer?.Email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontSize: 20 }}>Trainer Mobile:</TableCell>
              <TableCell style={{ fontSize: 20 }}>{Trainer?.Mobile}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontSize: 20 }}>GramPanchyat Name:</TableCell>
              <TableCell style={{ fontSize: 20 }}>{Trainer?.GramPanchyat}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontSize: 20 }}>Module Name:</TableCell>
              <TableCell style={{ fontSize: 20 }}>{Trainer?.Module}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontSize: 20 }}>Start Date:</TableCell>
              <TableCell style={{ fontSize: 20 }}>{Trainer?.startDate}</TableCell>
            </TableRow>
          </Table>
          <Typography style={{ margin: 20, fontSize: 30, fontWeight: 600 }}>Categories</Typography>
          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 50, flexWrap: 'wrap', padding: '0px 10%' }}>
            {categories.map((row) => {
              return (
                <div>
                  <div style={{ display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#1d5a96',margin:10 }}>
                  <Typography style={{ fontSize:24,fontWeight:700,marginLeft:10,color:'white' }}> {row?.Name}</Typography>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', flexWrap: 'wrap' }}>
                    <div className='imgContainer'>
                      <div className='img'>
                        <a target="_blank" href={Image1}><img src={Image1} style={{ height: '100%', width: '100%' }} /></a>
                      </div>
                    </div>
                    <div className='imgContainer'>
                      <div className='img'>
                        <a target="_blank" href={Image2}><img src={Image2} style={{ height: '100%', width: '100%' }} /></a>
                      </div>
                    </div>
                    <div className='imgContainer'>
                      <div className='img'>
                        <a target="_blank" href={Image2}><img src={Image2} style={{ height: '100%', width: '100%' }} /></a>
                      </div>
                    </div>
                    <div className='imgContainer'>
                      <div className='img'>
                        <a target="_blank" href={Image2}><img src={Image2} style={{ height: '100%', width: '100%' }} /></a>
                      </div>
                    </div>
                    <div className='imgContainer'>
                      <div className='img'>
                        <a target="_blank" href={Image2}><img src={Image2} style={{ height: '100%', width: '100%' }} /></a>
                      </div>
                    </div>
                    <div className='imgContainer'>
                      <div className='img'>
                        <a target="_blank" href={Image2}><img src={Image2} style={{ height: '100%', width: '100%' }} /></a>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <center>
          <Button variant="contained" style={{ margin: 20 }} onClick={() => navigate(-1)}>Back</Button>
        </center>
      </Card>
      <Modal
        className={classes.middlePosition}
        open={openModal}
        onClose={(e) => {
          e.preventDefault();
          setOpenModal(!openModal);
        }}
      >
        <Paper className={classes.profilemodal} style={{ marginTop: 70 }}>
          <Stack direction="row" justifyContent="space-between"
            alignItems="center" spacing={2}>
            <Stack direction="column">
              <Typography variant='h6' component='div'>Activity</Typography>
            </Stack>
            <IconButton aria-label="delete" onClick={e => {
              e.preventDefault();
              setOpenModal(!openModal);
            }}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ fontSize: 20, fontWeight: 600, padding: '0px 20px', }}>Activity</div>
              <div style={{ fontSize: 20, fontWeight: 600, padding: '0px 20px' }}>RCID</div>
              <div style={{ fontSize: 20, fontWeight: 600, padding: '0px 20px' }}>Category</div>
              <div style={{ fontSize: 20, fontWeight: 600, padding: '0px 20px' }}>Gram Panchayat</div>
              <div style={{ fontSize: 20, fontWeight: 600, padding: '0px 20px' }}>Activity Date</div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
              <div style={{ fontSize: 20, fontWeight: 400, padding: '0px 20px' }}>Activity_Name</div>
              <div style={{ fontSize: 20, fontWeight: 400, padding: '0px 20px' }}>RCID_Number</div>
              <div style={{ fontSize: 20, fontWeight: 400, padding: '0px 20px' }}>Category_Name</div>
              <div style={{ fontSize: 20, fontWeight: 400, padding: '0px 20px' }}>Gram_Panchayat</div>
              <div style={{ fontSize: 20, fontWeight: 400, padding: '0px 20px' }}>12/12/2022</div>
            </div>
          </div>
          <h3>Images</h3>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', flexWrap: 'wrap' }}>
            <div className='imgContainer'>
              <div className='img'>
                <a target="_blank" href={Image1}><img src={Image1} style={{ height: '100%', width: '100%' }} /></a>
              </div>
            </div>
            <div className='imgContainer'>
              <div className='img'>
                <a target="_blank" href={Image2}><img src={Image2} style={{ height: '100%', width: '100%' }} /></a>
              </div>
            </div>
          </div>
        </Paper>
      </Modal>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: 'column',
    marginTop: 30
  },
  card: {
    width: '1200px',
    borderRadius: '20px !important',
    margin: '20px',
    padding: '20px',
  },
  passmodal: {
    width: '350px',
    background: 'white',
    borderRadius: '20px !important',
    margin: '20px',
    padding: '20px',
  },
  profilemodal: {
    width: '1000px',
    background: 'white',
    borderRadius: '20px !important',
    margin: '20px',
    padding: '20px',
  },
  middlePosition: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  input: {
    width: '70%',
    background: 'white',
    marginTop: '20px',
    padding: '5px 15px'
  },
  image: {
    width: 150,
    height: 150
  },
  imageApprove: {
    backgroundColor: 'green',
    color: 'white'
  },
  imageReject: {
    backgroundColor: 'red',
    color: 'white'
  }
}));

const mapStateToProps = (state) => {
  return {
    userDetails: state.auth.userDetails,
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ViewApprovedItems)