import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Button,
  TablePagination,
  Box,
  Paper,
  CardContent,
  Modal,
  IconButton,
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Alert,
  CircularProgress,
} from "@mui/material";
import * as Action from "../../actions/adminAction";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import PersonAddRounded from "@mui/icons-material/PersonAddRounded";
import { Edit, Visibility, Delete } from "@mui/icons-material";
import React, { useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import { useEffect } from "react";
import axios from "axios";

//   {
//     label: "Akola",
//     value: "Akola",
//   },
//   {
//     label: "Pune",
//     value: "Pune",
//   },
//   {
//     label: "Beed",
//     value: "Beed",
//   },
//   {
//     label: "Kolhapur",
//     value: "Kolhapur",
//   },
//   {
//     label: "Mumbai",
//     value: "Mumbai",
//   },
//   {
//     label: "Nagpur",
//     value: "Nagpur",
//   },
//   {
//     label: "Sangali",
//     value: "Sangali",
//   },
// ];

export const AllTalukaList = ({
  getAllDistrictData,
  getAllTalukaData,
  userDetails,
  getDistrictWiseTalukaData
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  //Modal variables
  const [AddTaluka, setAddTaluka] = useState(false);
  const [EditTaluka, setEditTaluka] = useState(false);

  //Table Pagination variables
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [TalukaData, setTalukaData] = useState([]);
  const [DistrictData, setDistrictData] = useState([]);
  //Table Pagination Functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //data variables

  const [District, setDistrict] = useState("");
  const [taluka, setTaluka] = useState("");
  const [EditData, setEditData] = useState("");
  const [deleteTaluka, setDeleteTaluka] = useState()
  const [taluka_id, setId] = useState()
  const [loader,setLoader] = useState(false)

  //Error variables

  const [districError, setdistricError] = useState(false);
  const [TalukaError, setTalukaError] = useState(false);
  const [error, setError] = useState(false);
  //Get all taluka list

  const DeleteTaluka = async () => {
  
    await axios
      .put(process.env.REACT_APP_BASE_URL+"/taluka/deletetaluka", {
        taluka_id,
      })
      .then((responce) => {
        // console.log(
        //   "responce from DeleteTaluka Alltalukalist component",
        //   responce.data
        // );
        getTaluka();
      })
      .catch((err) => {
        console.log("error from DeleteTaluka Alltalukalist component", err);
      });
  };

  const validate = () => {
    let IsformValid = true;

    if (!District) {
      IsformValid = false;
      setdistricError(true);
    }

    if (!taluka) {
      IsformValid = false;
      setTalukaError(true);
    }else if(taluka.match(/[0-9+@#$&%!~]/)){
      IsformValid = false;
      setError(true)
    }

    return IsformValid;
  };

  const openTaluka = () => {
    setDistrict("");
    setTaluka("");
    setAddTaluka(true);
  };


  const handleSubmit = async () => {
    setdistricError(false);
    setTalukaError(false);
    const talukaName = taluka;

    const districtName = District?.districtName;
    const distric_id = District?._id;
    if (validate()) {
      console.log("Form Valid");

      await axios
        .post(process.env.REACT_APP_BASE_URL+"/taluka/inserttaluka", {
          talukaName,

          districtName,
          distric_id,
        })
        .then((responce) => {
          console.log(
            "responce from handlesubmit Alltaluka component",
            responce.data
          );
          if (responce?.data?.code === 11000) {
            alert("Allready Available");
          }
        })
        .catch((err) => {
          console.log("error from handlesubmit Alltaluka component", err);
        });
      getTaluka();
      setAddTaluka(false);
    } else {
      console.log("Form Invalid");
    }
  };
  const EdithandleSubmit = async () => {
    setTalukaError(false);
    const talukaName = taluka;

    const taluka_id = EditData?._id;
    if (validate()) {
      console.log("Form Valid");

      await axios
        .put(process.env.REACT_APP_BASE_URL+"/taluka/talukaupdate", {
          talukaName,

          taluka_id,
        })
        .then((responce) => {
          console.log(
            "responce from handlesubmit Alltaluka component",
            responce.data
          );
        })
        .catch((err) => {
          console.log("error from handlesubmit Alltaluka component", err);
        });
      getTaluka();
      setEditTaluka(false);
    } else {
      console.log("Form Invalid");
    }
  };

  const openEdit = (row) => {
    setTaluka(row?.talukaName);
    setDistrict(row?.districtName);
    setEditData(row);
    setEditTaluka(true);
  };
  const openDelete = (row) => {
    // setTaluka(row?.talukaName);
    setId(row?._id)
    setDeleteTaluka(true)
  }

  const getDistrict = async () => {
    const result = await getAllDistrictData();

    if (result !== "No data found") {
      setDistrictData(result);
      // setDistrictData(result);
    }
  };

  const getTaluka = async () => {
    setLoader(true)
    const result = await getAllTalukaData();
    if (result !== "No data found") {
      setTalukaData(result);
      setLoader(false)
    }else{
      setLoader(false)
    }
  };

  const getDistrictTaluka = async () => {
    setdistricError(false)
    if(District){
      const result = await getDistrictWiseTalukaData({
        distric_id: District?._id
      });
  
      if (result !== "No data found") {
        setTalukaData(result);
      }
    }else{
      setdistricError(true)
    }

  }

  useEffect(() => {
    getDistrict();
    getTaluka();
  }, []);

  return (
    <div className={classes.main}>
      <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <Box sx={{ flex: 1 }}>
          <h1>Taluka Data</h1>
        </Box>
        <Box>
          <div style={{ marginTop: 30, right: 0 }}>
            <Button variant="contained" onClick={() => openTaluka()}>
              <PersonAddRounded style={{ marginRight: 10 }} />
              Add Taluka
            </Button>
          </div>
        </Box>
      </div>

      <div style={{ display: "flex", flexDirection: "row", marginBottom: 50 }}>
        <FormControl sx={{ m: 1, marginRight: 1 }}>
          <InputLabel id="outlined-adornment-Bank">District</InputLabel>
          <Select
            style={{ width: 250 }}
            id="outlined-adornment-Bank"
            label="District"
            value={District}
            onChange={(e) => {
              setDistrict(e.target.value);
            }}
            MenuProps={{
              style: {
                maxHeight: 400,
              },
            }}
          >
            {DistrictData &&
              DistrictData.map((item) => {
                return (
                  <MenuItem key={item?._id} value={item}>
                    {item?.districtName}
                  </MenuItem>
                );
              })}
          </Select>
          {districError ? (
            <span style={{ color: "red", fontSize: 15 }}>
              *Please Select District
            </span>
          ) : null}
        </FormControl>
        <Button
          sx={{ m: 1, marginRight: 1 }}
          variant="contained"
          // style={{ fontSize: 14 }}
          onClick={() => getDistrictTaluka()}
        >
          Get Data
        </Button>
        <Button
          sx={{ m: 1, marginRight: 1 }}
          variant="contained"
          // style={{ fontSize: 14 }}
          onClick={() => getTaluka()}
        >
          Get All Data
        </Button>
      </div>

        {
          loader ? <CircularProgress color="primary" /> :  <Paper sx={{ overflow: "hidden" }}>
          <TableContainer>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                  <strong>District Name</strong>
                </TableCell>
                <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                  <strong>Taluka Name</strong>
                </TableCell>
  
                <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                  <strong>Action</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {TalukaData?.length > 0 ? (
                TalukaData?.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                ).map((row, index) => {
                  return (
                    <TableRow>
                      <TableCell style={{ fontSize: 14, textAlign: "center" }}>
                        {row?.districtName}
                      </TableCell>
                      <TableCell style={{ fontSize: 14, textAlign: "center" }}>
                        {row?.talukaName}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <Button
                          variant="contained"
                          style={{ margin: 5, backgroundColor: "green" }}
                          onClick={() => openEdit(row)}
                        >
                          <Edit style={{ marginRight: 5 }} />
                          Edit
                        </Button>
                        <Button
                          onClick={() => {
                            // DeleteTaluka(row);
                            openDelete(row)
                          }}
                          variant="contained"
                          style={{ margin: 5, backgroundColor: "red" }}
                        >
                          <Delete style={{ marginRight: 5 }} />
                          Delete
                        </Button>
                        {/* <Button variant="contained" style={{ margin: 5 }}>
                          <Visibility style={{ marginRight: 5 }} />
                          View
                        </Button> */}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>NO Data Found</TableRow>
              )}
            </TableBody>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            // count={count ? count : 0}
            count={TalukaData?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        }

      {/* Add GramPanchayat */}
      <Modal
        className={classes.middlePosition}
        open={AddTaluka}
        onClose={(e) => {
          e.preventDefault();
          setAddTaluka(false);
        }}
      >
        <Paper className={classes.profilemodal}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Stack direction="column">
              <Typography variant="h6" component="div">
                Add Taluka
              </Typography>
            </Stack>
            <IconButton
              aria-label="delete"
              onClick={(e) => {
                e.preventDefault();
                setAddTaluka(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CardContent
              style={{
                display: "flex",
                width: "90%",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                {/* <FormControl sx={{ m: 1, marginRight: 3 }}>
                  <TextField
                    style={{ width: 250 }}
                    error={GramPanchayat.match(/[0-9+@#$&%!~]/)}
                    id="outlined-adornment-TrainerName"
                    label="Grampanchyat Name"
                    value={GramPanchayat}
                    onChange={(e) => setGramPanchayat(e.target.value)}
                    helperText={
                      panchyatError ? (
                        <span style={{ color: "red", fontSize: 15 }}>
                          *Please Enter GramPanchyat Name
                        </span>
                      ) : null
                    }
                  />
                </FormControl> */}
                {/* <FormControl sx={{ m: 1, marginRight: 3 }}>
                  <TextField
                    style={{ width: 250 }}
                    error={desc.match(/[0-9+@#$&%!~]/)}
                    id="outlined-adornment-TrainerName"
                    label="Description"
                    value={desc}
                    onChange={(e) => setDesc(e.target.value)}
                  />
                </FormControl> */}
                <FormControl sx={{ m: 1, marginRight: 3 }}>
                  <InputLabel id="outlined-adornment-bank">District</InputLabel>
                  <Select
                    style={{ width: 250 }}
                    id="outlined-adornment-bank"
                    label="District"
                    value={District}
                    onChange={(e) => setDistrict(e.target.value)}
                    MenuProps={{
                      style: {
                        maxHeight: 400,
                      },
                    }}
                  >
                    { DistrictData && DistrictData !== "No data  found" &&
                      DistrictData.length > 0
                      ? DistrictData.map((option) => (
                        <MenuItem key={option._id} value={option}>
                          {option.districtName}
                        </MenuItem>
                      ))
                      : null}
                  </Select>
                  {districError ? (
                    <span style={{ color: "red", fontSize: 15 }}>
                      *Please Select District
                    </span>
                  ) : null}
                </FormControl>

                <FormControl sx={{ m: 1, marginRight: 3 }}>
                  <TextField
                    style={{ width: 250 }}
                    error={taluka.match(/[0-9+@#$&%!~]/)}
                    id="outlined-adornment-TrainerName"
                    label="Taluka Name"
                    value={taluka}
                    onChange={(e) => setTaluka(e.target.value)}
                    helperText={
                      TalukaError ? (
                        <span style={{ color: "red", fontSize: 15 }}>
                          *Please Enter Taluka Name
                        </span>
                      ) : null
                    }
                  />
                </FormControl>
              </div>
            </CardContent>
            {error ? (
              <Alert
                severity="warning"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setError(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Please fill requested form properly
              </Alert>
            ) : null}
            <Button
              variant="contained"
              style={{ margin: 10 }}
              endIcon={<SendIcon />}
              onClick={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              Submit
            </Button>
          </div>
        </Paper>
      </Modal>

      {/* Edit Taluka*/}
      <Modal
        className={classes.middlePosition}
        open={EditTaluka}
        onClose={(e) => {
          e.preventDefault();
          setEditTaluka(false);
        }}
      >
        <Paper className={classes.profilemodal}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Stack direction="column">
              <Typography variant="h6" component="div">
                Edit taluka
              </Typography>
            </Stack>
            <IconButton
              aria-label="delete"
              onClick={(e) => {
                e.preventDefault();
                setEditTaluka(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CardContent
              style={{
                display: "flex",
                // width: "90%",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <FormControl sx={{ m: 1, marginRight: 3 }}>
                  <TextField
                    style={{ width: 250 }}
                    // error={taluka.match(/[0-9+@#$&%!~]/)}
                    id="outlined-adornment-TrainerName"
                    label="District Name"
                    value={EditData?.districtName}
                  />
                </FormControl>
                <FormControl sx={{ m: 1, marginRight: 3 }}>
                  <TextField
                    style={{ width: 250 }}
                    // error={taluka.match(/[0-9+@#$&%!~]/)}
                    id="outlined-adornment-TrainerName"
                    label="Taluka Name"
                    value={taluka}
                    onChange={(e) => setTaluka(e.target.value)}
                    helperText={
                      TalukaError ? (
                        <span style={{ color: "red", fontSize: 15 }}>
                          *Please Enter Taluka Name
                        </span>
                      ) : null
                    }
                  />
                </FormControl>

                {/* <FormControl sx={{ m: 1, marginRight: 3 }}>
                  <InputLabel id="outlined-adornment-bank">District</InputLabel>
                  <Select
                    style={{ width: 250 }}
                    id="outlined-adornment-bank"
                    label="District"
                    value={District}
                    // onChange={(e) => setDistrict(e.target.value)}
                    MenuProps={{
                      style: {
                        maxHeight: 400,
                      },
                    }}
                    // disabled={true}
                  >
                    {DistrictData !== "No data  found" &&
                    DistrictData.length > 0
                      ? DistrictData.map((option) => (
                          <MenuItem key={option.districtName} value={option}>
                            {option.districtName}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                  {districError ? (
                    <span style={{ color: "red", fontSize: 15 }}>
                      *Please Select District
                    </span>
                  ) : null}
                </FormControl> */}
              </div>
            </CardContent>
            {error ? (
              <Alert
                severity="warning"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setError(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Please fill requested form properly
              </Alert>
            ) : null}
            <Button
              variant="contained"
              style={{ margin: 10 }}
              endIcon={<SendIcon />}
              onClick={(e) => {
                e.preventDefault();
                EdithandleSubmit();
              }}
            >
              Submit
            </Button>
          </div>
        </Paper>
      </Modal>
      {/* Delete Taluka  */}
      <Modal
        className={classes.middlePosition}
        open={deleteTaluka}
        onClose={(e) => {
          e.preventDefault();
          setDeleteTaluka(false);
        }}
      >
        <Paper className={classes.profilemodal}>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Stack direction="column">
              <Typography variant="h6" justifyContent={"center"} component="div" >
                Are You Sure You Want To Delete ?
              </Typography>
            </Stack>

          </Stack>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              margin: "21px"
            }}
          >

            <Button
              variant="contained"
              style={{ margin: 10, backgroundColor: "green" }}
              // endIcon={<SendIcon />}
              onClick={(e) => {
                e.preventDefault();
                setDeleteTaluka(false)
              }}
            >
              Cancle
            </Button>
            <Button
              variant="contained"
              style={{ margin: 10, backgroundColor: "red" }}
              // endIcon={<SendIcon />}
              onClick={(e) => {
                e.preventDefault();
                DeleteTaluka()
                setDeleteTaluka(false)
              }}
            >
              <Delete style={{ marginRight: 5 }} />

              Delete
            </Button>
          </div>
        </Paper>
      </Modal>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: 30,
  },
  card: {
    width: "800px",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  passmodal: {
    width: "350px",
    background: "white",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  profilemodal: {
    // width: "1000px",
    background: "white",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "35px",
  },
  middlePosition: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  input: {
    width: "70%",
    background: "white",
    marginTop: "20px",
    padding: "5px 15px",
  },

}));

const mapStateToProps = (state) => {
  return {
    userDetails: state.auth.userDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllDistrictData: () => dispatch(Action.getAllDistrictData()),
    getAllTalukaData: () => dispatch(Action.getAllTalukaData()),
    getDistrictWiseTalukaData: (data) => dispatch(Action.getDistrictWiseTalukaData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllTalukaList);
