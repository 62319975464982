import React, { useState, useEffect } from "react";
import { connect } from 'react-redux'
import { makeStyles } from "@material-ui/styles";
import { Table, InputLabel, TableHead, TableBody, TableRow, TableCell, Typography, Button, Paper, Select, Modal, IconButton, TextField, FormControl, Card, CircularProgress, MenuItem } from "@mui/material";
import * as Action from "../../actions/adminAction"
import Image1 from "../../Assets/Image/satara_Mandave.JPG"
import Image2 from "../../Assets/Image/satara_Mandave_2.jpg"
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import Videos from "../../Assets/Image/Video.png"
import DOC from "../../Assets/Image/doc.png"
import VerifiedIcon from '@mui/icons-material/Verified';
require('../../App.css')

export const FeedbackForm = ({ }) => {

    const classes = useStyles()
    const navigate = useNavigate()
    const location = useLocation()
    const trainer = location.state
    const [Feedback, setFeedback] = useState([])

    //Feedback form
    // const [Feedback, setFeedback] = useState([
    //     { details: 'प्रशिक्षण आराखडा', सर्वोत्तम: '0', उत्तम: '0', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 1 },
    //     { details: 'प्रशिक्षण साहित्य', सर्वोत्तम: '0', उत्तम: '0', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 2 },
    //     { details: 'तांत्रिक परीक्षक', सर्वोत्तम: '0', उत्तम: '0', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 3 },
    //     { details: 'प्रात्यक्षिक सत्र', सर्वोत्तम: '0', उत्तम: '0', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 4 },
    //     { details: 'नाष्ट्याची गुणवत्ता', सर्वोत्तम: '0', उत्तम: '0', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 5 },
    //     { details: 'प्रशिक्षकांशी संवाद', सर्वोत्तम: '0', उत्तम: '0', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 6 },
    //     { details: 'प्रशिक्षणाची उपयोगिता', सर्वोत्तम: '0', उत्तम: '0', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 7 },
    //     { details: 'एकूण प्रभाव', सर्वोत्तम: '0', उत्तम: '0', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 8 },
    // ])

    const [selectedDetails, SetselectedDetails] = useState('')
    const [selectedसर्वोत्तम, Setselectedसर्वोत्तम] = useState('0')
    const [selectedउत्तम, Setselectedउत्तम] = useState('0')
    const [selectedबरा, Setselectedबरा] = useState('0')
    const [selectedअनपेक्षित, Setselectedअनपेक्षित] = useState('0')
    const [selectedवाईट, Setselectedवाईट] = useState('0')
    const [error, setError] = useState(false)

    const feedback_Dropdown = [
        'प्रशिक्षण आराखडा', 'प्रशिक्षण साहित्य', 'तांत्रिक परीक्षक', 'प्रात्यक्षिक सत्र', 'नाष्ट्याची गुणवत्ता', 'प्रशिक्षकांशी संवाद', 'प्रशिक्षणाची उपयोगिता', 'एकूण प्रभाव'
    ]

    const validate = () => {
        let IsformValid = true;

        if (!selectedDetails) {
            IsformValid = false;
        }

        if (!selectedसर्वोत्तम) {
            IsformValid = false;
        }

        if (!selectedउत्तम) {
            IsformValid = false
        }

        if (!selectedबरा) {
            IsformValid = false
        }

        if (!selectedअनपेक्षित) {
            IsformValid = false
        }

        if (!selectedवाईट) {
            IsformValid = false
        }

        console.log(IsformValid)
        return IsformValid;
    }

    const updateFeedback = () => {
        if (validate()) {
            let i = Feedback.findIndex(row => row?.details === selectedDetails)
            let arr = [...Feedback]
            arr[i] = {
                ...arr[i],
                सर्वोत्तम: selectedसर्वोत्तम,
                उत्तम: selectedउत्तम,
                बरा: selectedबरा,
                अनपेक्षित: selectedअनपेक्षित,
                वाईट: selectedवाईट
            }
            setFeedback(arr)
            console.log("updated form",arr)
            SetselectedDetails('')
            Setselectedसर्वोत्तम('0')
            Setselectedउत्तम('0')
            Setselectedबरा('0')
            Setselectedअनपेक्षित('0')
            Setselectedवाईट('0')

        } else {
            console.log("Form Invalid")
            setError(true)
        }
    }

    useEffect(() => {
        // getAllCategories()
        if (trainer) {
            if(trainer?.trainingModuleName === "4 Water Budget and crop Planning and interchange"){
            setFeedback([
            { details: 'प्रशिक्षण आराखडा', सर्वोत्तम: '26', उत्तम: '24', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 1 },
            { details: 'प्रशिक्षण साहित्य', सर्वोत्तम: '23', उत्तम: '27', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 2 },
            { details: 'तांत्रिक परीक्षक', सर्वोत्तम: '28', उत्तम: '22', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 3 },
            { details: 'प्रात्यक्षिक सत्र', सर्वोत्तम: '25', उत्तम: '25', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 4 },
            { details: 'नाष्ट्याची गुणवत्ता', सर्वोत्तम: '22', उत्तम: '28', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 5 },
            { details: 'प्रशिक्षकांशी संवाद', सर्वोत्तम: '27', उत्तम: '13', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 6 },
            { details: 'प्रशिक्षणाची उपयोगिता', सर्वोत्तम: '29', उत्तम: '21', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 7 },
            { details: 'एकूण प्रभाव', सर्वोत्तम: '26', उत्तम: '24', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 8 },
        ])
            }else if(trainer?.trainingModuleName === "5 Water Saving Technologies in Agriculture"){
                setFeedback([
                { details: 'प्रशिक्षण आराखडा', सर्वोत्तम: '22', उत्तम: '18', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 1 },
                { details: 'प्रशिक्षण साहित्य', सर्वोत्तम: '24', उत्तम: '16', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 2 },
                { details: 'तांत्रिक परीक्षक', सर्वोत्तम: '27', उत्तम: '13', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 3 },
                { details: 'प्रात्यक्षिक सत्र', सर्वोत्तम: '25', उत्तम: '15', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 4 },
                { details: 'नाष्ट्याची गुणवत्ता', सर्वोत्तम: '29', उत्तम: '11', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 5 },
                { details: 'प्रशिक्षकांशी संवाद', सर्वोत्तम: '24', उत्तम: '16', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 6 },
                { details: 'प्रशिक्षणाची उपयोगिता', सर्वोत्तम: '22', उत्तम: '18', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 7 },
                { details: 'एकूण प्रभाव', सर्वोत्तम: '23', उत्तम: '17', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 8 },
            ])
            }else{
                setFeedback([
                { details: 'प्रशिक्षण आराखडा', सर्वोत्तम: '14', उत्तम: '11', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 1 },
                { details: 'प्रशिक्षण साहित्य', सर्वोत्तम: '18', उत्तम: '7', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 2 },
                { details: 'तांत्रिक परीक्षक', सर्वोत्तम: '13', उत्तम: '12', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 3 },
                { details: 'प्रात्यक्षिक सत्र', सर्वोत्तम: '16', उत्तम: '9', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 4 },
                { details: 'नाष्ट्याची गुणवत्ता', सर्वोत्तम: '14', उत्तम: '11', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 5 },
                { details: 'प्रशिक्षकांशी संवाद', सर्वोत्तम: '19', उत्तम: '6', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 6 },
                { details: 'प्रशिक्षणाची उपयोगिता', सर्वोत्तम: '15', उत्तम: '10', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 7 },
                { details: 'एकूण प्रभाव', सर्वोत्तम: '13', उत्तम: '12', बरा: '0', अनपेक्षित: '0', वाईट: '0', id: 8 },
            ])
            }


        }
    }, [])
    
    return (
        <div className={classes.main}>
            <Card className={classes.profilemodal} style={{ backgroundColor: 'whitesmoke', flexWrap: 'wrap' }}>
                <div style={{ backgroundColor: '#1d5a96', display: 'flex', flexDirection: 'row', width: '100%', margin: 0 }}>
                    <IconButton style={{ margin: 10, padding: 0 }}>
                        <Typography style={{ fontFamily: 'sans-serif', fontWeight: 700, color: 'white', fontSize: 24, backgroundColor: '#1d5a96' }} sx={{
                        }} >Required data to generate report</Typography>
                    </IconButton>
                </div>
                <div>
                    <Table>
                        <TableRow>
                            <TableCell style={{ fontSize: 18, fontWeight: 600 }}>District </TableCell>
                            <TableCell style={{ fontSize: 18 }}>{trainer?.districtName}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontSize: 18, fontWeight: 600 }}>Taluka </TableCell>
                            <TableCell style={{ fontSize: 18 }}>{trainer?.talukaName}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontSize: 18, fontWeight: 600 }}>GramPnachayat </TableCell>
                            <TableCell style={{ fontSize: 18 }}>{trainer?.grampanchayatName}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontSize: 18, fontWeight: 600 }}>Module </TableCell>
                            <TableCell style={{ fontSize: 18 }}>{trainer?.trainingModuleName}</TableCell>
                        </TableRow>
                    </Table>
                </div>
                <div>
                    <div style={{ backgroundColor: '#1d5a96', display: 'flex', flexDirection: 'row', width: '100%', margin: 0 }}>
                        <IconButton style={{ margin: 5, padding: 0 }}>
                            <Typography style={{ fontFamily: 'sans-serif', fontWeight: 700, color: 'white', fontSize: 18, backgroundColor: '#1d5a96' }} sx={{
                            }} >Feedback Form</Typography>
                        </IconButton>
                    </div>
                    <div>
                        <center>
                            <FormControl sx={{ m: 2, paddingRight: 4 }} >
                                <InputLabel id="outlined-adornment-Bank">तपशील</InputLabel>
                                <Select
                                    style={{ width: 200 }}
                                    id="outlined-adornment-Bank"
                                    label="तपशील"
                                    value={selectedDetails}
                                    onChange={(e) => { SetselectedDetails(e.target.value); }}
                                    MenuProps={{
                                        style: {
                                            maxHeight: 400,
                                        },
                                    }}
                                >
                                    {feedback_Dropdown.map((item, index) => {
                                        return (
                                            <MenuItem key={index} value={item}>
                                                {item}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </center>
                        <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', marginTop: 20 }}>
                            <FormControl sx={{ m: 1 }}>
                                <TextField
                                    style={{ width: 120 }}
                                    // error={selectedसर्वोत्तम.match(/[A-Za-z+@#$&%!~]/)}
                                    id="outlined-adornment-TrainerName"
                                    label="सर्वोत्तम"
                                    value={selectedसर्वोत्तम}
                                    onChange={(e) => {
                                        if (!e.target.value.match(/[A-Za-z+@#$&%,;'".!~]/)) {
                                            Setselectedसर्वोत्तम(e.target.value)
                                        }
                                    }}
                                />
                            </FormControl>
                            <FormControl sx={{ m: 1 }}>
                                <TextField
                                    style={{ width: 120 }}
                                    id="outlined-adornment-TrainerName"
                                    label="उत्तम"
                                    value={selectedउत्तम}
                                    onChange={(e) => {
                                        if (!e.target.value.match(/[A-Za-z+@#$&%,;'".!~]/)) {
                                            Setselectedउत्तम(e.target.value)
                                        }
                                    }}
                                />
                            </FormControl>
                            <FormControl sx={{ m: 1 }}>
                                <TextField
                                    style={{ width: 120 }}
                                    id="outlined-adornment-TrainerName"
                                    label="बरा"
                                    value={selectedबरा}
                                    onChange={(e) => {
                                        if (!e.target.value.match(/[A-Za-z+@#$&%,;'".!~]/)) {
                                            Setselectedबरा(e.target.value)
                                        }
                                    }}
                                />
                            </FormControl>
                            <FormControl sx={{ m: 1 }}>
                                <TextField
                                    style={{ width: 120 }}
                                    id="outlined-adornment-TrainerName"
                                    label="अनपेक्षित"
                                    value={selectedअनपेक्षित}
                                    onChange={(e) => {
                                        if (!e.target.value.match(/[A-Za-z+@#$&%,;'".!~]/)) {
                                            Setselectedअनपेक्षित(e.target.value)
                                        }
                                    }}
                                />
                            </FormControl>
                            <FormControl sx={{ m: 1 }}>
                                <TextField
                                    style={{ width: 120 }}
                                    id="outlined-adornment-TrainerName"
                                    label="वाईट"
                                    value={selectedवाईट}
                                    onChange={(e) => {
                                        if (!e.target.value.match(/[A-Za-z+@#$&%,;'".!~]/)) {
                                            Setselectedवाईट(e.target.value)
                                        }
                                    }}
                                />
                            </FormControl>
                        </div>
                        <center>
                            <Button style={{ margin: 5 }} variant="contained" onClick={() => updateFeedback()}>Update</Button>
                        </center>
                    </div>
                    <div style={{ backgroundColor: '#1d5a96', display: 'flex', flexDirection: 'row', width: '100%', margin: 10, height: 1 }} />
                    <div style={{ width: '100%' }}>
                        <Table style={{ margin: 10, background: 'white', width: '100%', border: '1px solid black', borderCollapse: 'collapse' }}>
                            <TableHead style={{ border: '1px solid black', borderCollapse: 'collapse' }}>
                                <TableRow>
                                    <TableCell style={{ textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse', fontWeight: 600 }} rowSpan={2}>
                                        क्र
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse', fontWeight: 600 }} rowSpan={2}>
                                        तपशील
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse', fontWeight: 600 }} colSpan={5}>
                                        अभिप्राय श्रेणीनाय प्रशिक्षणार्थी संख्या
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse', fontWeight: 600 }}>सर्वोत्तम</TableCell>
                                    <TableCell style={{ textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse', fontWeight: 600 }}> उत्तम</TableCell>
                                    <TableCell style={{ textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse', fontWeight: 600 }}>बरा</TableCell>
                                    <TableCell style={{ textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse', fontWeight: 600 }}>अनपेक्षित</TableCell>
                                    <TableCell style={{ textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse', fontWeight: 600 }}>वाईट</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Feedback.length > 0 && Feedback.map((row) => {
                                    return (
                                        <TableRow>
                                            <TableCell style={{ textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse' }} >
                                                {row?.id}
                                            </TableCell>
                                            <TableCell style={{ textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse' }}>
                                                {row?.details}
                                            </TableCell>
                                            <TableCell style={{ textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse' }}>
                                                {row?.सर्वोत्तम}
                                            </TableCell>
                                            <TableCell style={{ textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse' }}>
                                                {row?.उत्तम}
                                            </TableCell>
                                            <TableCell style={{ textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse' }}>
                                                {row?.बरा}
                                            </TableCell>
                                            <TableCell style={{ textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse' }}>
                                                {row?.अनपेक्षित}
                                            </TableCell>
                                            <TableCell style={{ textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse' }}>
                                                {row?.वाईट}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </div>
                </div>
                <center>
                    <Button variant="contained" style={{ margin: 20 }} >Submit</Button>
                </center>
                <div style={{ backgroundColor: '#1d5a96', display: 'flex', flexDirection: 'row', width: '100%', margin: 0, height: 2 }} />
                <center>
                    <Button variant="contained" style={{ margin: 20 }} onClick={() => navigate(-1)} >Back</Button>
                    <Button variant="contained" style={{ margin: 20 }} onClick={() => window.location.reload()} >Reset</Button>
                </center>
            </Card>
            <Modal
                className={classes.middlePosition}
                open={error}
                onClose={(e) => {
                    e.preventDefault()
                    setError(false)
                }}
            >
                <Paper className={classes.passmodal}>
                    <Stack direction="row" justifyContent="space-between"
                        alignItems="center" spacing={2}>
                        <Stack direction="column">
                            <Typography variant='h6' component='div'>
                                Please make sure to Select the Details and enter All fields .
                            </Typography>
                        </Stack>
                        <IconButton aria-label="delete" onClick={e => {
                            e.preventDefault();
                            setError(!error);
                        }}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <center>
                        <Button variant="contained" onClick={() => setError(!error)} >OK</Button>
                    </center>
                </Paper>
            </Modal>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    main: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: 'column',
        marginTop: 30
    },
    card: {
        width: '70%',
        borderRadius: '20px !important',
        margin: '20px',
        padding: '20px',
    },
    passmodal: {
        width: '350px',
        background: 'white',
        borderRadius: '20px !important',
        margin: '20px',
        padding: '20px',
    },
    profilemodal: {
        width: '750px',
        background: 'white',
        borderRadius: '20px !important',
        margin: '20px',
        padding: '20px',
        flexWrap: 'wrap'
    },
    middlePosition: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    input: {
        width: '70%',
        background: 'white',
        marginTop: '20px',
        padding: '5px 15px'
    },
    image: {
        width: 200,
        height: 200
    },
    imageApprove: {
        backgroundColor: 'green',
        color: 'white'
    },
    imageReject: {
        backgroundColor: 'red',
        color: 'white'
    }
}));

const mapStateToProps = (state) => {
    return {
        userDetails: state.auth.userDetails,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getCategories: (data) => dispatch(Action.getCategories(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackForm)