import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Button,
  TablePagination,
  Box,
  Paper,
  CardContent,
  Modal,
  IconButton,
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  CircularProgress,
  Alert,
} from "@mui/material";
import * as Action from "../../actions/adminAction";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import PersonAddRounded from "@mui/icons-material/PersonAddRounded";
import { Edit, Visibility, Delete } from "@mui/icons-material";
import React, { useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import axios from "axios";
import { useEffect } from "react";

export const AllGrampanchyatList = ({ }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  //Modal variables
  const [AddDistrict, setAddDistrict] = useState(false);
  const [EditDistrict, setEditDistrict] = useState(false);
  const [DeleteDist, setDeleteDist] = useState(false);
  const [distric_id, setId] = useState()
  const [AddMsg,setAddMSg] = useState(false)


  //Table Pagination variables
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  //Table Pagination Functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //data variables

  const [District, setDistrict] = useState("");

  const [AllDistrict, setAllDistrict] = useState("");
  //Error variables

  const [districError, setdistricError] = useState(false);
  const [loader, setLoader] = useState(false)

  const [error, setError] = useState(false);

  //Edit Variable
  const [EditSelect, setEditSelect] = useState("");

  const validate = () => {
    let IsformValid = true;

    if (!District) {
      IsformValid = false;
      setdistricError(true);
    }else if(District.match(/[0-9+@#$&%!~]/)){
      IsformValid = false;
      setError(true)
    }

    return IsformValid;
  };

  const openDistrict = () => {
    setDistrict("");

    setAddDistrict(true);
  };
  const DeleteDistrict = async () => {

    const ID = distric_id
    await axios
      .put(process.env.REACT_APP_BASE_URL+"/distric/deletedistric", {
        distric_id: ID,
      })
      .then((responce) => {
        // console.log(
        //   "responce from DeleteDistrict from Alldistrict",
        //   responce.data
        // );
        GetDistrictData();
      })
      .catch((err) => {
        console.log("responce from DeleteDistrict from Alldistrict", err);
      });
  };

  const handleSubmit = async () => {
    setdistricError(false);

    if (validate()) {
      console.log("Form Valid");
      const districtName = District;

      await axios
        .post(process.env.REACT_APP_BASE_URL+"/distric/insertdistric", {
          districtName,
        })
        .then((responce) => {
          console.log(
            "responce from handlesubmit in alldistrict compoinent",
            responce.data.code
          );
          if (responce?.data?.code === 11000) {
            alert("Allready Available");
          }else {
            setAddDistrict(false);
            setAddMSg(true)
            GetDistrictData();
          }
        })
        .catch((err) => {
          console.log("error from handlesubmit in alldistrict compoinent", err);
        });
    } else {
      console.log("Form Invalid");
    }
  };
  const EdithandleSubmit = async () => {
    setdistricError(false);

    if (validate()) {
      console.log("Form Valid");

      // const distric_id = EditSelect?._id;
      const districtName = District;
      await axios
        .put("https://gsdapi.foxberry.link//v1/distric/districtupdate", {
          distric_id,
          districtName,
        })
        .then((responce) => {
          // console.log(
          //   "responce from EdithandleSubmit in alldistrict compoinent",
          //   responce.data
          // );
          setEditDistrict(false);
        })
        .catch((err) => {
          console.log(
            "error from EdithandleSubmit in alldistrict compoinent",
            err
          );
        });

      setEditDistrict(false);
      GetDistrictData();
    } else {
      console.log("Form Invalid");
    }
  };

  const openEdit = (row) => {
    setEditSelect(row);
    setId(row._id)
    setDistrict(row?.districtName);
    setEditDistrict(true);
  };
  const openDelete = (row) => {
    // setEditSelect(row);
    setId(row?._id)

    setDistrict(row?.districtName);
    setDeleteDist(true);
  };

  const GetDistrictData = async () => {
    setLoader(true)
    await axios
      .get(process.env.REACT_APP_BASE_URL+"/distric/getdistriclist")
      .then((responce) => {
        console.log(
          "responce from GetDistrictData from alldistrict component",
          responce.data
        );
        if (responce.data !== "No data found") {
          setAllDistrict(responce.data);
          setLoader(false)
        }
      })
      .catch((err) => {
        console.log(
          "error from GetDistrictData from alldistrict component",
          err
        );
        setLoader(false)
      });
  };
  useEffect(() => {
    GetDistrictData();
  }, []);

  return (
    <div className={classes.main}>
      <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <Box sx={{ flex: 1 }}>
          <h1>District Data</h1>
        </Box>
        <Box>
          <div style={{ marginTop: 30, right: 0 }}>
            <Button variant="contained" onClick={() => openDistrict()}>
              <PersonAddRounded style={{ marginRight: 10 }} />
              Add District
            </Button>
          </div>
        </Box>
      </div>
      {loader ? <CircularProgress color="primary" /> : <Paper sx={{ overflow: "hidden" }}>
        <TableContainer>
          <TableHead>
            <TableRow>
              <TableCell
                style={{ fontSize: 16, textAlign: "center", marginLeft: 60 }}
              >
                <strong>District Name</strong>
              </TableCell>

              <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                <strong>Action</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {AllDistrict.length > 0 ? (
              AllDistrict.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              ).map((row, index) => {
                return (
                  <TableRow>
                    <TableCell style={{ fontSize: 16, textAlign: "center" }}>
                      {row?.districtName}
                    </TableCell>

                    <TableCell style={{ textAlign: "center" }}>
                      <Button
                        variant="contained"
                        style={{ margin: 5, backgroundColor: "green" }}
                        onClick={() => openEdit(row)}
                      >
                        <Edit style={{ marginRight: 5, }} />
                        Edit
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => {
                          // DeleteDistrict(row);
                          openDelete(row)

                        }}
                        style={{ margin: 5, backgroundColor: "red" }}
                      >
                        <Delete style={{ marginRight: 5 }} />
                        Delete
                      </Button>
                      {/* <Button variant="contained" style={{ margin: 5 }}>
                          <Visibility style={{ marginRight: 5 }} />
                          View
                        </Button> */}
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>NO Data Found</TableRow>
            )}
          </TableBody>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          // count={count ? count : 0}
          count={AllDistrict?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>}

      {/* Add District */}
      <Modal
        className={classes.middlePosition}
        open={AddDistrict}
        onClose={(e) => {
          e.preventDefault();
          setAddDistrict(false);
        }}
      >
        <Paper className={classes.profilemodal}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Stack direction="column">
              <Typography variant="h6" component="div">
                Add District
              </Typography>
            </Stack>
            <IconButton
              aria-label="delete"
              onClick={(e) => {
                e.preventDefault();
                setAddDistrict(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CardContent
              style={{
                display: "flex",
                width: "90%",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <FormControl sx={{ m: 1, marginRight: 3 }}>
                  <TextField
                    style={{ width: 250 }}
                    error={District.match(/[0-9+@#$&%!~]/)}
                    id="outlined-adornment-TrainerName"
                    label="District Name"
                    value={District}
                    onChange={(e) => setDistrict(e.target.value)}
                    helperText={
                      districError ? (
                        <span style={{ color: "red", fontSize: 15 }}>
                          *Please Enter District Name
                        </span>
                      ) : null
                    }
                  />
                </FormControl>
              </div>
            </CardContent>
            {error ? (
              <Alert
                severity="warning"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setError(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Please fill requested form properly
              </Alert>
            ) : null}
            <Button
              variant="contained"
              style={{ margin: 10 }}
              endIcon={<SendIcon />}
              onClick={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              Submit
            </Button>
          </div>
        </Paper>
      </Modal>

      {/* Edit District */}
      <Modal
        className={classes.middlePosition}
        open={EditDistrict}
        onClose={(e) => {
          e.preventDefault();
          setEditDistrict(false);
        }}
      >
        <Paper className={classes.profilemodal}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Stack direction="column">
              <Typography variant="h6" component="div">
                Edit District
              </Typography>
            </Stack>
            <IconButton
              aria-label="delete"
              onClick={(e) => {
                e.preventDefault();
                setEditDistrict(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CardContent
              style={{
                display: "flex",
                width: "90%",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <FormControl sx={{ m: 1, marginRight: 3 }}>
                  <TextField
                    style={{ width: 250 }}
                    error={District.match(/[0-9+@#$&%!~]/)}
                    id="outlined-adornment-TrainerName"
                    label="District Name"
                    value={District}
                    onChange={(e) => setDistrict(e.target.value)}
                    helperText={
                      districError ? (
                        <span style={{ color: "red", fontSize: 15 }}>
                          *Please Enter District Name
                        </span>
                      ) : null
                    }
                  />
                </FormControl>
              </div>
            </CardContent>
            {error ? (
              <Alert
                severity="warning"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setError(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Please fill requested form properly
              </Alert>
            ) : null}
            <Button
              variant="contained"
              style={{ margin: 10 }}
              endIcon={<SendIcon />}
              onClick={(e) => {
                e.preventDefault();
                EdithandleSubmit();
              }}
            >
              Submit
            </Button>
          </div>
        </Paper>
      </Modal>
      {/* Delete District */}
      <Modal
        className={classes.middlePosition}
        open={DeleteDist}
        onClose={(e) => {
          e.preventDefault();
          setDeleteDist(false);
        }}
      >
        <Paper className={classes.profilemodal}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Stack direction="column">
              <Typography variant="h6" justifyContent={"center"} component="div">
                Are You Sure Delete District
              </Typography>
            </Stack>

          </Stack>

          <div style={{ display: "flex", paddingTop: "21px", flexDirection: "row" }}>


            <Button
              variant="contained"
              style={{ margin: 10 }}
              onClick={(e) => {
                e.preventDefault();
                // EdithandleSubmit();
                setDeleteDist(false)
              }}
            >
              cancle
            </Button>
            <Button
              variant="contained"
              style={{ margin: 10, backgroundColor: "red" }}
              onClick={(e) => {
                e.preventDefault();
                DeleteDistrict()
                setDeleteDist(false)

              }}
            >
              <Delete style={{ marginRight: 5 }} />

              Delete

            </Button>
          </div>
        </Paper>
      </Modal>

      <Modal
        className={classes.middlePosition}
        open={AddMsg}
        onClose={(e) => {
          e.preventDefault();
          setAddMSg(false);
        }}
      >
        <Paper className={classes.profilemodal}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Stack direction="column">
              <Typography variant="h6" justifyContent={"center"} component="div">
                District Added Successfully !
              </Typography>
            </Stack>

          </Stack>
          <center>
            <Button variant="contained" onClick={ () => setAddMSg(false) } >OK</Button>
          </center>
        </Paper>
      </Modal>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: 30,
  },
  card: {
    width: "800px",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  passmodal: {
    width: "350px",
    background: "white",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  profilemodal: {
    // width: "1000px",
    background: "white",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "49px",
  },
  middlePosition: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  input: {
    width: "70%",
    background: "white",
    marginTop: "20px",
    padding: "5px 15px",
  },
}));

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllGrampanchyatList);
