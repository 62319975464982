import { connect } from 'react-redux'
import { makeStyles } from "@material-ui/styles";
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Typography, Button, TablePagination, Box, Paper, CardContent, Modal, IconButton, TextField, FormControl, Select, InputLabel, MenuItem, Alert, OutlinedInput, Chip } from "@mui/material";
import * as Action from "../../actions/adminAction"
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from '@mui/icons-material/Send';
import { useNavigate } from "react-router-dom";
import PersonAddRounded from "@mui/icons-material/PersonAddRounded";
import { Edit, Visibility, Delete } from "@mui/icons-material";
import React, { useEffect, useState } from 'react';
// import Button from '@mui/material/Button';
// import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Compressor from 'compressorjs';
import S3 from "react-aws-s3";
import CircularProgress from '@mui/material/CircularProgress';
import img from "../../Assets/Image/nope-not-here.webp"
import ModeIcon from '@mui/icons-material/Mode';
import axios from 'axios';
window.Buffer = window.Buffer || require("buffer").Buffer;
const DocType = [
  {
    'label': 'Image',
    'AcceptedDocs': 'image/*'
  },
  {
    'label': 'Video',
    'AcceptedDocs': 'video/*'
  },
  {
    'label': 'Documents',
    'AcceptedDocs': ".xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.csv,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  },
]

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const TrainingCategories = ({ userDetails }) => {

  // hooks and Props
  const classes = useStyles()

  //data variables
  const [TrainingCategoryName, setTrainingCategoryName] = useState('')
  const [description, setDescription] = useState()
  const [image, setImage] = useState('')
  const [TrainerData, setTrainerData] = useState([])
  const [imgUploading, setImgUploading] = useState(false);
  const [imgArray, setImgArray] = useState();
  const [imgUploadingError, setImgUploadingError] = useState(false);
  const [editData, setEditData] = useState()
  const [loader, setLoader] = useState(false)
  const [dataLoad, setDataLoad] = useState(false)
  const [addTrainingCategory, setAddTrainingCategory] = useState()
  const [trainingCategory_id, setId] = useState()
  const [deleteTrainingCategory, setDeleteTrainingCategory] = useState()
  const [CategoryDocType, setCategoryDocType] = useState([])


  //Table Pagination variables
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  //Table Pagination Functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //Modal variables
  const [openTrainingModule, setOpenTrainingModule] = useState(false)
  const [EditTrainingModule, setEditTrainingModule] = useState(false)

  //Error variables
  const [NameError, setNameError] = useState(false)
  const [DescriptionError, setDescriptionError] = useState(false)
  const [imageError, setImageError] = useState(false)
  const [Error, setError] = useState(false)
  const [DocError,setDocError] = useState(false)


  useEffect(() => {
    getTrainerData()
  }, [])


  const handleChange = async (e) => {
    console.log("Changing Profile pic", e.target.files)
    // if (e.target.files.length > categoryName.qty)
    //   alert("Cannot upload more than 10 Images")
    if (e.target.files.length) {
      const img = URL.createObjectURL(e.target.files[0]);
      console.log("Files Type", e.target.files[0])
      setLoader(true)
      uploadImageToS3(e.target.files[0]);

    }
  }



  const uploadImageToS3 = (event) => {
    setImgUploading(true);
    const config = {
      bucketName: process.env.REACT_APP_BUCKET_NAME,
      dirName: process.env.REACT_APP_DIR_NAME + "/" + TrainingCategoryName,
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_ACCESS_ID,
      secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
      s3Url: "https://gsda-images2.s3.ap-south-1.amazonaws.com"
    };

    let fileInput = false;
    const image = event;
    console.log("image data before compression-------------- ", image);
    if (event) {
      fileInput = true;
    }
    if (fileInput) {
      new Compressor(image, {
        quality: 0.9,
        success: (compressedResult) => {
          let getTimeStamp = new Date().getTime();
          let newFileName = getTimeStamp + "_";
          console.log("------- filename image upload --------", newFileName);

          const ReactS3Client = new S3(config);

          ReactS3Client.uploadFile(compressedResult, newFileName)
            .then((res) => {
              // setImage(res.location);

              if (res.status === 204) {
                setImgUploading(false);
                console.log("uploded image", res);
                console.log("image uploaded successfully!!!!");
                setImgArray(res.location);
                setLoader(false)
              }
              else {
                setImgUploadingError(true);
                setLoader(false)
              }
              //   if (type === "ProfilePic") {
              //     // if (image !== null && image !== userDetails?.profilePhotoURL) {
              //     //   // updateProfilePic({ profilePhotoURL: res.location, user_Id: userDetails?._id })
              //     // }
              //   } else if (type === "Logo1") {
              //     // setlogo1(res.location)
              //   } else if (type === "Logo2") {
              //     // setlogo2(res.location)
              //   }
            }).catch(err => {

              console.error(err);
            });
        },
      });
      console.log("--------------- Upload image -----------");
    }
  };



  const getTrainerData = async () => {
    setDataLoad(true)
    await axios.get(process.env.REACT_APP_BASE_URL+"/trainingcategory/gettrainingcategorylist")
      .then((res) => {
        console.log("hello.........", res.data);
        setTrainerData(res.data)
        setDataLoad(false)
      })
      .catch((err) => {
        setDataLoad(false)
        console.log("error ", err);
      })
    // setTrainerData(TrainerData)
  }
  const editTraningCategory = async () => {
    await axios.put(process.env.REACT_APP_BASE_URL+"/trainingmcgp/trainingmodule/cat/gp/update")
      .then((res) => {
        setEditData(res.data)
      })
      .catch((err) => {
        console.log("error in edit taluka", err);
      })
  }

  const foramvaliadate = () => {
    TrainingCategoryName.trim()
    description.trim()
    let IsformValid = true;

    if (!TrainingCategoryName) {
      IsformValid = false;
      setNameError(true)
    } else if (TrainingCategoryName.match(/[0-9+@#$&%!~]/)) {
      IsformValid = false;
      setError(true)
    } else if (TrainingCategoryName.replace(/\s/g, "").length <= 0) {
      setNameError(true)
      IsformValid = false

    }

    if (!imgArray) {
      IsformValid = false;
      setImageError(true)
      setError(true)

    }

    if (!description) {
      IsformValid = false;
      setDescriptionError(true)
      setError(true)

    } else if (description.replace(/\s/g, "").length <= 0) {
      setDescriptionError(true)
      IsformValid = false

    }

    if(CategoryDocType.length === 0){
      setDocError(true)
      setError(true)
      IsformValid = false;
    }

    return IsformValid;
  }

  const handleAdd = async () => {
    setNameError(false)
    setDescriptionError(false)
    setImageError(false)

    if (foramvaliadate() && Error === false) {

      let type = []
      CategoryDocType.map( (item) => {
        type.push(item?.AcceptedDocs)
      } )

      const data = {
        "trainingCategoryName":TrainingCategoryName,
        "description":description,
        "imgURL":imgArray,
        "createdBy":userDetails?._id,
        "typeOfDoc":type
      }
        await axios.post(process.env.REACT_APP_BASE_URL+"/trainingcategory/inserttrainingcategory",data)
          .then((res) => {
            console.log("response from handle Add ", res);
            setOpenTrainingModule(false)
            getTrainerData()
            setCategoryDocType([])
          })
          .catch((err) => {
            console.log("err from handle Add", err);
          })

      setOpenTrainingModule(false)

    } else {
      console.log("Form Invalid")
    }
  }
  const openModule = () => {
    setTrainingCategoryName("")
    setDescription("")
    setImgArray("")

    setOpenTrainingModule(true)

  }

  const openEdit = (row) => {
    setTrainingCategoryName(row?.trainingCategoryName)
    setDescription(row?.description)
    setImgArray(row.imgURL)
    setId(row?._id)
    setCategoryDocType(row?.typeOfDoc)
    setEditTrainingModule(true)

  }
  const openDelete = (row) => {
    setId(row?._id)
    setDeleteTrainingCategory(true)
  }

  const handleDelete = async () => {

    await axios.put(process.env.REACT_APP_BASE_URL+"/trainingcategory/trainingcategorydelete", { trainingCategory_id })
      .then((res) => {

        console.log("response from handleDelete", res.data);
        setDeleteTrainingCategory(false)
        getTrainerData()
      })
      .catch((err) => {
        console.log("error from handle delete", err);
      })



  }


  const handleEdit = async (e) => {

    // setNameError(false)
    // setMobileError(false)
    // setEmailError(false)
    // setAddError(false)

    if (foramvaliadate() && Error === false) {
      const trainingCategoryName = TrainingCategoryName;
      // consttrainingCategory_id = trainingCategory_id;

      console.log("Form Valid")
      await axios.put(process.env.REACT_APP_BASE_URL+"/trainingcategory/trainingcategoryupdate", { trainingCategory_id, trainingCategoryName, description })
        .then((res) => {

          console.log("response from editHandle", res);
          setEditTrainingModule(false)
          getTrainerData()
        })
        .catch((err) => {
          console.log("error from handle Edit", err);
        })
      //   console.log("Trainer Name", TrainerName)
      //   console.log("Trainer Mobile", TrainerMobile)
      //   console.log("Trainer Email", TrainerEmail)
      //   console.log("Trainer Address", TrainerAdderess)
    } else {
      console.log("Form Invalid")
    }
  }

  const handleCategoryDoc = (event) => {
    const {
      target: { value },
    } = event
    setCategoryDocType(
      typeof value.AcceptedDocs === 'string' ? value.split(',') : value
    )
  }

  return (
    <div className={classes.main}>
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <Box sx={{ flex: 1 }}>
          <h1>Evidence Captured Category</h1>
        </Box>
        <Box >
          <div style={{ marginTop: 30, right: 0 }}>
            <Button variant="contained" onClick={() => openModule()} ><PersonAddRounded style={{ marginRight: 10 }} />Add Training Category</Button>
          </div>
        </Box>
      </div>
      {dataLoad ? <CircularProgress color='primary' /> :
        <Paper sx={{ overflow: 'hidden', width: '90%', marginTop: 2 }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>SR No.</strong></TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Training Category</strong></TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Description</strong></TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Image</strong></TableCell>
                  <TableCell style={{ fontSize: 16, textAlign: 'center' }}><strong>Action</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  TrainerData !== 'No data found' && TrainerData.length > 0 ? TrainerData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{index + 1}</TableCell>
                        <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{row?.trainingCategoryName}</TableCell>
                        <TableCell style={{ fontSize: 16, textAlign: 'center' }}>{row?.description}</TableCell>
                        <TableCell style={{ fontSize: 16, textAlign: 'center' }}>
                          <a target="_blank" href={row?.imgURL ? row?.imgURL : null} ><img src={row?.imgURL ? row?.imgURL : img} style={{ width: 100, height: 100 }} /></a>
                        </TableCell>
                        <TableCell style={{ fontSize: 16, textAlign: 'center' }}>
                          <ModeIcon variant='contained' style={{ color: "green" }} onClick={() => openEdit(row)} />
                          <Delete variant='contained' style={{ color: "red" }} onClick={() => openDelete(row)} />
                        </TableCell>
                      </TableRow>
                    )
                  })
                    : <TableRow>NO Data Found</TableRow>}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            // count={count ? count : 0}
            count={ TrainerData !== "No data found" && TrainerData?.length > 0 ? TrainerData?.length : 0 }
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>}

      {/* Add Training Category */}
      <Modal
        className={classes.middlePosition}
        open={openTrainingModule}
        onClose={(e) => {
          // e.preventDeafault();
          setOpenTrainingModule(false)

        }}
      >
        <Paper className={classes.profilemodal} >
          <Stack direction="row" justifyContent="space-between"
            alignItems="center" spacing={2}>
            <Stack direction="column">
              <Typography variant='h6' component='div'>Add Traning Category</Typography>
            </Stack>
            <IconButton aria-label="delete" onClick={e => {
              e.preventDefault();
              setOpenTrainingModule(false)
              setImgArray(null)

            }}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }} >
            <CardContent style={{ display: 'flex', width: '90%', justifyContent: 'center', flexDirection: 'column' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <FormControl sx={{ m: 1, marginRight: 3 }}>
                  <TextField
                    style={{ width: 300 }}
                    error={TrainingCategoryName.match(/[0-9+@#$&%!~]/)}
                    trainingCategory_id="outlined-adornment-TrainerName"
                    label="Training Module Name"
                    value={TrainingCategoryName}
                    onChange={(e) => setTrainingCategoryName(e.target.value)}
                    helperText={NameError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Enter Traning Category Name</span> : null}
                  />
                </FormControl>
                <FormControl sx={{ m: 1, marginRight: 3 }}>
                  <TextField
                    style={{ width: 300 }}
                    // error={description.match(/[A-Za-z+@#$&%!~]/) || TrainerMobile.length !== 10 && TrainerMobile.length !== 0}
                    trainingCategory_id="outlined-adornment-TrainerName"
                    label="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    helperText={DescriptionError ? <span style={{ color: 'red', fontSize: 15 }}>* Please Enter Description</span> : null}
                  />
                </FormControl>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ flex:0.98 }}>
                  <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Button variant="contained" component="label">
                        Upload
                        <input accept={TrainingCategoryName ? TrainingCategoryName.type : "image/*"} className={classes.input} trainingCategory_id="icon-button-file" type="file" onChange={(e) => handleChange(e)} style={{ display: "none" }} />
                      </Button>
                      {imageError ? <span style={{ color: 'red', fontSize: 15 }}>* Please Upload Image</span> : null}

                      {imgArray ? <img src={imgArray} style={{ width: "80px", height: "80px" }} /> : null}
                      {loader ? <CircularProgress /> : null}
                    </Stack>
                  </FormControl>
                </div>
                <div>
                  <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel id="demo-multiple-chip-label">Select Categories</InputLabel>
                    <Select
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      multiple
                      value={CategoryDocType}
                      onChange={handleCategoryDoc}
                      input={<OutlinedInput id="select-multiple-chip" label="Select Categories" />}
                      renderValue={(Selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {
                            Selected.map((item) => (
                              <Chip key={item?.label} label={item?.label} />
                            ))
                          }
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {DocType !== 'No data found' && DocType.length > 0 ?
                        DocType.map((row) => {
                          return (
                            <MenuItem
                              key={row?.label}
                              value={row}
                            >
                              {row?.label}
                            </MenuItem>
                          )
                        })
                        : null}
                    </Select>
                    { DocError ? <span style={{ color:'red' }}>*Please Select Category Type</span> : null }
                  </FormControl>
                </div>
              </div>
            </CardContent>
            {Error ? <Alert
              severity='warning'
              action={
                <IconButton
                  aria-label='close'
                  color='inherit'
                  size='small'
                  onClick={() => {
                    setError(false)
                  }}
                >
                  <CloseIcon fontSize='inherit' />
                </IconButton>
              }
            >
              Please fill requested form properly
            </Alert> : null}
            <Button variant="contained"
              style={{ margin: 10 }}
              endIcon={<SendIcon />}
              onClick={(e) => {
                e.preventDefault();

                handleAdd()
              }} >
              ADD Training Category
            </Button>
          </div>
        </Paper>
      </Modal>

      {/* Update Trainer */}
      <Modal
        className={classes.middlePosition}
        open={EditTrainingModule}
        onClose={(e) => {
          // e.preventDeafault();
          setEditTrainingModule(false)
          setImgArray(false)
        }}
      >
        <Paper className={classes.profilemodal} >
          <Stack direction="row" justifyContent="space-between"
            alignItems="center" spacing={2}>
            <Stack direction="column">
              <Typography variant='h6' component='div'>Update Training Category</Typography>
            </Stack>
            <IconButton aria-label="delete" onClick={e => {
              e.preventDefault();
              setEditTrainingModule(false)
              setImgArray(null)

            }}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }} >
            <CardContent style={{ display: 'flex', width: '90%', justifyContent: 'center', flexDirection: 'column' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <FormControl sx={{ m: 1, marginRight: 3 }}>
                  <TextField
                    style={{ width: 300 }}
                    // error={TrainingCategoryName.match(/[0-9+@#$&%!~]/)}
                    trainingCategory_id="outlined-adornment-TrainerName"
                    label="Training Module Name"
                    value={TrainingCategoryName}
                    onChange={(e) => setTrainingCategoryName(e.target.value)}
                    helperText={NameError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Enter Training Category Name</span> : null}
                  />
                </FormControl>
                <FormControl sx={{ m: 1, marginRight: 3 }}>
                  <TextField
                    style={{ width: 300 }}
                    // error={TrainerMobile.match(/[A-Za-z+@#$&%!~]/) || TrainerMobile.length !== 10 && TrainerMobile.length !== 0}
                    trainingCategory_id="outlined-adornment-TrainerName"
                    label="Description "
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    helperText={DescriptionError ? <span style={{ color: 'red', fontSize: 15 }}>*Please Enter Description.</span> : null}
                  />
                </FormControl>
              </div>

            </CardContent>
            {Error ? <Alert
              severity='warning'
              action={
                <IconButton
                  aria-label='close'
                  color='inherit'
                  size='small'
                  onClick={() => {
                    setError(false)
                  }}
                >
                  <CloseIcon fontSize='inherit' />
                </IconButton>
              }
            >
              Please fill requested form properly
            </Alert> : null}
            <Button variant="contained"
              style={{ margin: 10 }}
              endIcon={<SendIcon />}
              onClick={(e) => {

                e.preventDefault();
                // setTrainerData(e.target.value)

                handleEdit()
              }} >
              Update Training Category
            </Button>
          </div>
        </Paper>
      </Modal>
      {/* delete Training Module */}
      <Modal

        className={classes.middlePosition}
        open={deleteTrainingCategory}
        onClose={(e) => {
          e.preventDeafault();
          setDeleteTrainingCategory(false)
        }}
      >
        <Paper className={classes.profilemodal} >
          <Stack direction="row" justifyContent="space-between"
            alignItems="center" spacing={2}>

            <IconButton aria-label="delete" >
            </IconButton>
          </Stack>
          <Stack direction="row" style={{ display: "flex", justifyContent: "center" }} >
            <Typography variant='h6' style={{ textAlign: "center", justifyContent: "center" }} component='div'>Are You Want To Delete Training Category</Typography>
          </Stack>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', margin: "21px" }} >


            <Button variant="contained"
              style={{ margin: 10 }}
              onClick={(e) => {
                e.preventDefault();
                setDeleteTrainingCategory(false)

              }} >

              Cancle
            </Button>
            <Button variant="contained"
              style={{ margin: 10, backgroundColor: "red" }}
              endIcon={<Delete />}
              onClick={(e) => {
                e.preventDefault();
                handleDelete()
                setDeleteTrainingCategory(false)


              }} >
              Delete
            </Button>
          </div>
        </Paper>
      </Modal>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: 'column',
    marginTop: 30
  },
  card: {
    width: '1200px',
    borderRadius: '20px !important',
    margin: '20px',
    padding: '20px',
  },
  passmodal: {
    width: '350px',
    background: 'white',
    borderRadius: '20px !important',
    margin: '20px',
    padding: '20px',
  },
  profilemodal: {
    width: '800px',
    background: 'white',
    borderRadius: '20px !important',
    margin: '20px',
    padding: '20px',
  },
  middlePosition: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  input: {
    width: '70%',
    background: 'white',
    marginTop: '20px',
    padding: '5px 15px'
  },
}));

const mapStateToProps = (state) => {
  return {
    userDetails: state.auth.userDetails,
  }
}

const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(TrainingCategories)